<template>
    <div class="card shadow mb-4 col-md-6">
        <!-- Card Header - Accordion -->
        <a
            :class="{ disabled: addDisabled }"
            @click.prevent="enableAdd()"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-product"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                <span v-if="!fresh_product.id">Add New</span
                ><span v-else>Modify</span> Product
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div :class="this.accordion.collapse" class="collapse" id="new-product">
            <div class="card-body">
                <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
                    <form
                        id="product-form"
                        @submit.prevent="handleSubmit(processForm)"
                        class="main-form"
                        role="form"
                    >
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Name</label>
                                <ValidationProvider
                                    name="name"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_product.name"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        v-uppercase
                                        placeholder="nome"
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Ean Code</label>
                                <ValidationProvider
                                    name="ean_code"
                                    rules="required|numeric"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_product.ean_code"
                                        type="number"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        placeholder="EAN CODE"
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Code</label>
                                <ValidationProvider
                                    name="code"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_product.code"
                                        :placeholder="product_code"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        v-uppercase
                                        @dblclick="assignPlaceHolderToValue"
                                        data-vv-validate-on="input"
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                            <div class="form-group col-md-6">
                                <label>Category</label>
                                <ValidationProvider
                                    name="category"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <select
                                        v-model="category_id"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                    >
                                        <option selected disabled>
                                            Please select one
                                        </option>
                                        <option
                                            v-for="c in categories"
                                            v-bind:key="c.id"
                                            v-bind:value="c.id"
                                        >
                                            {{ c.name }}
                                        </option>
                                    </select>
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="form-row">
                            <div
                                v-if="category.has_frame"
                                class="form-group col-md-6"
                            >
                                <label>Frame color</label>
                                <ValidationProvider
                                    name="frame"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <select
                                        v-model="frame_id"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                    >
                                        <option selected>
                                            Please select one
                                        </option>
                                        <option
                                            v-for="f in framelist"
                                            v-bind:key="f.id"
                                            v-bind:value="f.id"
                                        >
                                            {{ f.name }}
                                        </option>
                                    </select>
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                            <div
                                v-if="category.has_lens"
                                class="form-group col-md-6"
                            >
                                <label>Lens color</label>
                                <ValidationProvider
                                    name="lens"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <select
                                        v-model="lens_id"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                    >
                                        <option selected>
                                            Please select one
                                        </option>
                                        <option
                                            v-for="l in lenslist"
                                            v-bind:key="l.id"
                                            v-bind:value="l.id"
                                        >
                                            {{ l.name }}
                                        </option>
                                    </select>
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <div class="form-row">
                                    <div class="col-md-7">
                                        <div class="form-group">
                                            <label>Quantity</label>
                                            <ValidationProvider
                                                name="quantity"
                                                rules=""
                                                v-slot="{ classes, errors }"
                                            >
                                                <select
                                                    :disabled="
                                                        fresh_product.preorder ==
                                                        1
                                                    "
                                                    v-model="fresh_product.qty"
                                                    class="form-control"
                                                    :class="{
                                                        'border-danger':
                                                            classes.failed,
                                                    }"
                                                >
                                                    <option selected>
                                                        Please select one
                                                    </option>
                                                    <option :value="null">
                                                        Not Managed
                                                    </option>
                                                    <option :value="0">
                                                        Out of stock
                                                    </option>
                                                    <option
                                                        v-for="n in 60"
                                                        :value="n"
                                                        :key="n"
                                                    >
                                                        {{ n }}
                                                    </option>
                                                </select>
                                                <i
                                                    v-show="errors[0]"
                                                    class="fas fa-exclamation-circle text-danger"
                                                ></i>
                                                <span
                                                    class="help text-danger"
                                                    >{{ errors[0] }}</span
                                                >
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label>Volume</label>
                                            <ValidationProvider
                                                name="volume"
                                                rules="required"
                                                v-slot="{ classes, errors }"
                                            >
                                                <select
                                                    v-model="
                                                        fresh_product.volume
                                                    "
                                                    class="form-control"
                                                    :class="{
                                                        'border-danger':
                                                            classes.failed,
                                                    }"
                                                >
                                                    <option :value="1">
                                                        0.1
                                                    </option>
                                                    <option :value="10">
                                                        1
                                                    </option>
                                                    <option :value="100">
                                                        10
                                                    </option>
                                                </select>
                                                <i
                                                    v-show="errors[0]"
                                                    class="fas fa-exclamation-circle text-danger"
                                                ></i>
                                                <span
                                                    class="help text-danger"
                                                    >{{ errors[0] }}</span
                                                >
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Suggested Price</label>
                                        <currency-input
                                            v-model="
                                                fresh_product.suggested_price
                                            "
                                            class="currency_input form-control"
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            !preorder && !fresh_product.preorder
                                        "
                                        class="col-md-2"
                                    >
                                        <span></span>
                                    </div>
                                    <div
                                        v-if="!preorder"
                                        class="form-group col-md-3"
                                    >
                                        <label>Best Seller</label>
                                        <ValidationProvider
                                            name="best_seller"
                                            rules=""
                                            v-slot="{ classes, errors }"
                                        >
                                            <input
                                                v-model="
                                                    fresh_product.best_seller
                                                "
                                                type="checkbox"
                                                class="form-control chk-best-seller"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                            />
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div v-else class="col-md-2">
                                        <span></span>
                                    </div>
                                    <div
                                        v-if="
                                            preorder || fresh_product.preorder
                                        "
                                        class="form-group col-md-3"
                                    >
                                        <label>Pre-order</label>
                                        <ValidationProvider
                                            name="pre_order"
                                            rules=""
                                            v-slot="{ classes, errors }"
                                        >
                                            <input
                                                v-model="fresh_product.preorder"
                                                type="checkbox"
                                                class="form-control chk-best-seller"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                            />
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-5 offset-md-1">
                                <img
                                    v-if="fresh_product.image"
                                    class="w-75 d-block img-thumbnail"
                                    :src="img_thumb"
                                />
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-9 offset-md-3">
                                <label>Image Url</label>
                                <ValidationProvider
                                    name="image url"
                                    rules="url"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_product.image"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        placeholder="image url"
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>

                        <div class="form-row mb-3">
                            <div class="form-group col-md-11">
                                <label>Gallery Images</label>
                                <br />
                                <p><small>Get product's gallery from website.</small>
                                        <button
                                            type="button"
                                            :disabled="!fresh_product.ean_code"
                                            @click="findProductGallery"
                                            class="btn btn-primary btn-circle btn-sm"
                                        >
                                            <i class="fas fa-download"></i>
                                        </button>
                                    </p>
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider
                                            name="gallery urls"
                                            rules=""
                                            v-slot="{ classes, errors }"
                                        >
                                            <textarea
                                                v-model="wp_gallery"
                                                rows="6"
                                                wrap="off"
                                                class="form-control"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                            >
                                            </textarea>
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="row py-3">
                                    <div
                                        class="col d-flex justify-content-around"
                                        v-if="wp_gallery"
                                    >
                                        <div
                                            v-for="(t, index) in gallery_thumbs"
                                            :key="index"
                                            class="align-items-end p-2"
                                        >
                                            <img
                                                v-if="t"
                                                :src="t"
                                                alt="Thumbnail"
                                                class="img-fluid img-thumbnail rounded"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <input hidden v-model="fresh_product.id" />
                            <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
                            <button class="btn btn-primary">Save</button>
                            <div @click="resetForm()" class="btn btn-danger">
                                Cancel
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["entity", "extra"],
    data() {
        return {
            fresh_product: {},
            accordion: {
                aria: false,
                collapse: "",
            },
            addDisabled: false,
            categories: this.extra.categories,
            framelist: this.extra.frames,
            lenslist: this.extra.lenslist,
            category_id: null,
            frame_id: null,
            lens_id: null,
            category: {},
            preorder: true,
            wp_id: null,
            variation_id: null,
            wp_gallery: null,
        };
    },
    computed: {
        product_code() {
            let pc = "";

            if (!this.fresh_product.id) {
                if (!_.isEmpty(this.category)) {
                    pc = pc.concat("AO", this.category.code);
                }

                if (this.category && (this.frame || this.lens)) {
                    let frame_code = this.frame ? this.frame.code : "";
                    let lens_code = this.lens ? this.lens.code : "";
                    if (this.category.has_frame && this.category.has_lens) {
                        pc = pc.concat(frame_code, "-", lens_code);
                    } else {
                        pc = pc.concat(frame_code, lens_code);
                    }
                }
            }
            return pc;
        },
        frame() {
            return (
                this.framelist.find((item) => item.id === this.frame_id) || null
            );
        },
        lens() {
            return (
                this.lenslist.find((item) => item.id === this.lens_id) || null
            );
        },
        img_thumb() {
            if (this.fresh_product.image) {
                return this.parsePath(this.fresh_product.image);
            }
        },
        gallery_thumbs() {
            if (this.wp_gallery && !this.fresh_product.gallery) {

                Vue.set(this.fresh_product, "gallery", this.wp_gallery);
            }
            const regex = /-([^.-]*x[^.-]*)\.(jpg|png|gif|jpeg|webp)$/;

            return (
                this.fresh_product.gallery.map((url) =>
                    this.parsePath(url, "micro")
                ) ?? []
            );
        },
    },
    watch: {
        entity(newVal) {
            if (this.entity.id) {
                this.addDisabled = true;
                this.accordion.aria = true;
                this.accordion.collapse = "show";
                this.category_id = this.entity.category.id;
                this.frame_id = this.entity.framecolor
                    ? this.entity.framecolor.id
                    : null;
                this.lens_id = this.entity.lenscolor
                    ? this.entity.lenscolor.id
                    : null;
                this.preorder = false;
            }

            this.fresh_product = Object.assign({}, this.entity);

            Vue.set(this.fresh_product, "category", this.category_id);
            Vue.set(this.fresh_product, "framecolor", this.frame_id);
            Vue.set(this.fresh_product, "lenscolor", this.lens_id);
        },
        category_id: function (val) {
            if (_.isEmpty(this.entity)) {
                Vue.set(this.fresh_product, "category", val);
            }
            this.category =
                this.categories.find((item) => item.id === val) || {};

            this.frame_id =
                this.fresh_product.framecolor &&
                this.fresh_product.category == this.category.id
                    ? this.fresh_product.framecolor
                    : null;
            this.lens_id =
                this.fresh_product.lenscolor &&
                this.fresh_product.category == this.category.id
                    ? this.fresh_product.lenscolor
                    : null;
            this.wp_gallery = this.fresh_product.gallery ? this.fresh_product.gallery.join("\n") : [];
            
        },
        frame_id: function (val) {
            //if (_.isEmpty(this.entity)) {
            Vue.set(this.fresh_product, "frame", val);
            //}
        },
        lens_id: function (val) {
            //if (_.isEmpty(this.entity)) {
            Vue.set(this.fresh_product, "lens", val);
            //}
        },
        wp_gallery: function (val) {
            
            if (Object.keys(val).length !== 0) {
                this.fresh_product.gallery = val.split("\n").filter((el) => el !== '');
            }
            else{
                this.fresh_product.gallery = [];
            }
        },
    },
    methods: {
        resetForm() {
            this.accordion.aria = false;
            this.accordion.collapse = "";
            this.addDisabled = false;
            this.$refs.formObs.reset();
            this.category_id = null;
            this.frame_id = null;
            this.lens_id = null;
            this.preorder = true;
            this.wp_gallery = null;
            this.wp_id = null;
            this.variation_id = null;
            this.$emit("resetForm");
        },
        enableAdd() {
            if (!this.accordion.aria && this.accordion.collapse == "") {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            } else {
                this.resetForm();
            }
        },
        processForm() {
            this.$refs.formObs.validate().then((result) => {
                if (this.entity.id) {
                    this.$emit("updateItem", this.fresh_product);
                } else {
                    this.$emit("createItem", this.fresh_product);
                }
            });
        },
        assignPlaceHolderToValue(event) {
            if (!this.fresh_product.code) {
                this.fresh_product.code = this.product_code;
                event.target.blur();
                setTimeout(event.target.focus(), 1000);
            }
        },
        findProductGallery() {
            this.loader = this.showLoader(this.$el);
            this.getData(route("product.gallery", [this.fresh_product.ean_code]))
                .then((result) => {
                    if (result.data) {
                        if(!this.fresh_product.image){
                            let main_image = result.data.shift();
                            this.fresh_product.image = main_image;
                        }

                        if (
                            !_.isEmpty(this.entity) &&
                            !_.isEmpty(this.fresh_product.gallery)
                        ) {
                            this.wp_gallery = this.wp_gallery.concat(
                                "\n",
                                result.data.join("\n")
                            );
                        } else {
                            this.wp_gallery = result.data.join("\n");
                        }
                    }
                    this.loader.hide();
                })
                .catch((error) => {
                    this.loader.hide();
                    console.log(error);
                });
        },
    },
};
</script>
