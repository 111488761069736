/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap')

window.Vue = require('vue')

//Custom Plugin for Axios
import CallerService from './plugins/caller-service'

//Custom Vue Utilities
import Utilities from './plugins/utitities'

//Custom Vue Filters
import './plugins/filters'

//Vue Good Table
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

//Vue ScroolTo
import VueScrollTo from 'vue-scrollto'

//Vue Toast Notification
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

//Vue Currency Input
import VueCurrencyInput from 'vue-currency-input'

//Vue Currency Filter
import VueCurrencyFilter from 'vue-currency-filter'

//Vue Loading Overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

//Vue Marquee
import MarqueeText from 'vue-marquee-text-component'

import ShowNotification from './plugins/show-notification'

import { UpperCase, LowerCase } from './directives/FontDirectives'

import imageFallback from './directives/imageFallback'

// Vee Validate
import {
    ValidationObserver,
    ValidationProvider
} from './extensions/vee-validate'
import Vue from 'vue'

Vue.use(CallerService)
Vue.use(Utilities)

Vue.use(VueGoodTablePlugin)
Vue.use(VueScrollTo) //, { offset: -90 }) //90 header height
Vue.use(VueToast, {
    position: 'top-right',
    duraton: 2000
})

Vue.use(VueCurrencyFilter, {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'back',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
})

Vue.use(Loading, {
    loader: 'dots',
    canCancel: false,
    lockScroll: true,
    enforceFocus: true,
})

Vue.directive('uppercase', UpperCase)
Vue.directive('lowercase', LowerCase)
Vue.directive('image-fall-back', imageFallback)
// Vue.directive('capitalize', Capitalize)

Vue.use(ShowNotification)

Vue.use(VueCurrencyInput, {
    globalOptions: {
        currency: 'EUR',
        locale: 'it',
        precision: 2,
        distractionFree: true
    }
})


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component(
    'modal',
    require('./components/common/Modal.vue').default
)
Vue.component(
    'main-component',
    require('./components/MainComponent.vue').default
)
Vue.component(
    'profile-component',
    require('./components/ProfileComponent.vue').default
)
Vue.component(
    'common-table',
    require('./components/tables/CommonTable.vue').default
)
Vue.component(
    'form-category',
    require('./components/forms/CategoryForm.vue').default
)
Vue.component(
    'form-framecolor',
    require('./components/forms/FrameColorForm.vue').default
)
Vue.component(
    'form-lenscolor',
    require('./components/forms/LensColorForm.vue').default
)
Vue.component(
    'form-product',
    require('./components/forms/ProductForm.vue').default
)
Vue.component(
    'form-user',
    require('./components/forms/UserForm.vue').default
)
Vue.component(
    'form-profile',
    require('./components/forms/ProfileForm.vue').default
)
Vue.component(
    'form-password',
    require('./components/forms/PasswordForm.vue').default
)
Vue.component(
    'pricelist-component',
    require('./components/PriceListComponent.vue').default
)
Vue.component(
    'form-pricelist',
    require('./components/forms/PriceListForm.vue').default
)
Vue.component(
    'form-advice',
    require('./components/forms/AdviceForm.vue').default
)
Vue.component(
    'form-shipping-cost',
    require('./components/forms/ShippingCostForm.vue').default
)
Vue.component(
    'pricelist-table',
    require('./components/tables/PriceListTable.vue').default
)
Vue.component(
    'form-order',
    require('./components/forms/OrderForm.vue').default
)

Vue.component(
    'genuinecode-component',
    require('./components/GenuineCodeComponent.vue').default
)
Vue.component(
    'form-genuine_code',
    require('./components/forms/GenuineCodeForm.vue').default
)
Vue.component(
    'form-notify_product',
    require('./components/forms/NotifyProductForm.vue').default
)
Vue.component(
    'genuine-code-table',
    require('./components/tables/GenuineCodeTable.vue').default
)
Vue.component(
    'update-order',
    require('./components/order/UpdateOrder.vue').default
)
Vue.component(
    'instruction-order',
    require('./components/order/InstructionOrder.vue').default
)

Vue.component(
    'user-order',
    require('./components/order/UserOrder.vue').default
)

Vue.component(
    'insight-component',
    require('./components/InsightComponent.vue').default
)

Vue.component(
    'form-agent',
    require('./components/forms/AgentForm.vue').default
)

Vue.component(
    'agent_orders-component',
    require('./components/AgentOrdersComponent.vue').default
)

Vue.component(
    'form-agent_order',
    require('./components/forms/AgentOrderForm.vue').default
)

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('marquee-text', MarqueeText)

const app = new Vue({
    el: '#app'
})
