<template>
    <div class="col col-md-12">
        <nav>
            <div class="nav nav-tabs insight-tabs" id="nav-tab" role="tablist">
                <button
                    class="nav-link"
                    :class="{ active: activeTab === 'best-sellers' }"
                    @click="activeTab = 'best-sellers'"
                    type="button"
                    role="tab"
                    aria-controls="nav-best-sellers"
                    aria-selected="activeTab === 'best-sellers'"
                >
                    Best-selling Products
                </button>
                <button
                    class="nav-link"
                    :class="{ active: activeTab === 'customer-purchases' }"
                    @click="activeTab = 'customer-purchases'"
                    type="button"
                    role="tab"
                    aria-controls="nav-customer-purchases"
                    aria-selected="activeTab === 'customer-purchases'"
                >
                    Customer Purchases
                </button>
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div                
                class="tab-pane fade "
                :class="{'show active' : activeTab === 'best-sellers'}"
                id="nav-best-sellers"
                role="tabpanel"
                aria-labelledby="nav-best-sellers-tab"
            >
                <default-insight
                    :headers="headerBs"
                    action="best-sellers"
                    title="Best-selling Products"
                    :entityName="entityName"
                    :entities="bsEntities"
                    @loadData="loadData"
                    initialSort=''
                >
                </default-insight>
            </div>
            <div               
                class="tab-pane fade "
                :class="{'show active' : activeTab === 'customer-purchases'}"
                id="nav-customer-purchases"
                role="tabpanel"
                aria-labelledby="nav-customer-purchases-tab"
            >
                <default-insight
                    :headers="headerCp"
                    action="customer-purchases"
                    title="Customer Purchases"
                    :entities="cpEntities"
                    :entityName="entityName"
                    @loadData="loadData"
                    initialSort='{"field": "amount", "type": "asc"}'
                >
                </default-insight>
            </div>
        </div>
    </div>
</template>
<script>
import DefaultInsight from "./insights/DefaultInsight.vue";

export default {
    props: ["entityName", "headers"],
    components: { DefaultInsight },
    data() {
        return {
            activeTab: "best-sellers", // Set the default active tab
            headerBs: this.headers["best-sellers"],
            headerCp: this.headers["customer-purchases"],
            cpEntities: [],
            bsEntities: [],
        };
    },
    methods: {
        loadData(param) {
            const searchUrl = route(this.entityName);
            this.searchData(
                { interval: param.date_interval, insight: param.action },
                searchUrl
            )
                .then((result) => {
                    switch (param.action) {
                        case "best-sellers":
                            this.bsEntities = result.data.entities;
                            break;
                        case "customer-purchases":
                            this.cpEntities = result.data.entities;
                            break;
                        default:
                            break;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>
