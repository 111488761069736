const CallerService = {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                async createCall(entity, url_service) {
                    try {
                        let response = await axios.post(
                            url_service,
                            entity
                        )
                        this.showMessage(
                            response.data.status_code,
                            response.data.message
                        )
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async updateCall(entity, url_update) {
                    let url_service = url_update.replace(
                        ':id',
                        entity.id
                    )
                    try {
                        let response = await axios.patch(
                            url_service,
                            entity
                        )
                        this.showMessage(
                            response.data.status_code,
                            response.data.message
                        )
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async deleteCall(entity, url_delete) {
                    let url_service = url_delete.replace(
                        ':id',
                        entity.id
                    )
                    try {
                        let response = await axios.delete(url_service)
                        this.showMessage(
                            response.data.status_code,
                            response.data.message
                        )
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async getData(url_service) {
                    try {
                        let response = await axios.get(url_service)
                        if (response.data.message) {
                            this.showMessage(
                                response.data.status_code,
                                response.data.message
                            )
                        }
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async searchData(term, url_service) {
                    try {
                        let response = await axios.post(url_service, term)
                        // this.showMessage(
                        //     response.data.status_code,
                        //     response.data.message
                        // )
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async noticeRestock(product, url_service) {
                    try {
                        let response = await axios.post(url_service, product)
                        this.showMessage(
                            response.data.status_code,
                            response.data.message
                        )
                        // return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async blobFileData(url_service) {
                     try {
                        let response = await axios.get(url_service, {responseType: 'blob'});
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                },
                async defaultData(url_service) {
                    try {
                        let response = await axios.get(url_service)
                        return response
                    } catch (error) {
                        let message =
                            error.response.data.error.message
                        let code = error.response.status
                        this.showMessage(code, message)
                        throw error.response
                    }
                }


            }
        })
    }
}

export default CallerService
