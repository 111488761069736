var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: 9
    },"search-options":{
      enabled: true
    },"styleClass":"vgt-table bordered","compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'price')?_c('span',[_c('currency-input',{staticClass:"currency_input",attrs:{"value":parseFloat(props.row.price)},on:{"change":function($event){_vm.value = $event},"blur":function($event){return _vm.handleBlur($event, props.row)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;_vm.handleBlur($event, props.row)
            _vm.nextRow($event)}}})],1):_c('span',[_vm._v("\n        "+_vm._s(props.formattedRow[props.column.field])+"\n      ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }