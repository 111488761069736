<template>
    <div>
        <!-- 
            :globalSearch="true"
            
        -->
        <vue-good-table
            ref="genuine_code_table"
            mode="remote"
            @on-search="searchCode"
            
            :columns="columns"
            :rows="rows"
            :search-options="{                
                enabled: true,
                skipDiacritics: true,
                placeholder: 'Search Code',
            }"
            styleClass="vgt-table bordered"
            compactMode
        >
        </vue-good-table>
    </div>
</template>

<script>
export default {
    name: "genuine-code-table",
    props: ["headers", "entities"],
    data() {
        return {
            columns: this.headers,
            rows: [],
            codes: this.entities,
        };
    },
    mounted() {
        this.rows = this.codes;
    },
    watch: {
        rows(newVal) {
            if (!newVal) {
                this.updateParams();
                this.$emit("onReset");
            }
        },
    },
    methods: {
        searchCode: _.debounce(function (params) {
            this.updateParams(params);
            this.$emit("onSearchCode", params);
            return false;
        }, 500),

        updateParams(newProps = null) {
            this.codes = Object.assign({}, this.codes, newProps);
        },
        getRecords(records) {
            this.rows = records;
            this.codes = records;
        },
    },
};
</script>
