<template>
    <div>
        <div class="row">
            <!-- Form Category Component -->
            <!-- <form-category
        ref="form_component"
        :category="entity"
        @createItem="createItem"
        @updateItem="updateItem"
        @resetForm="resetForm"
      ></form-category> -->
            <component
                v-if="componentForm"
                :is="componentForm"
                ref="form_component"
                :entity="entity"
                :extra="extra"
                @createItem="createItem"
                @updateItem="updateItem"
                @resetForm="resetForm"
                @onShowTable="tableDisplay"
            ></component>
        </div>
        <div class="list_table">
            <div class="row">
                <div class="col-lg-12">
                    <div class="panel panel-default">
                        <!-- /.panel-heading -->
                        <div class="panel-body">
                            <common-table
                                ref="table_component"
                                :entities="entities"
                                :headers="headers"
                                @onDelete="deleteItem"
                                @onEdit="editItem"
                                v-show="show_table"
                                :no_action="no_action"
                            ></common-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "headers",
        "lvEntities",
        "urls",
        "entityName",
        "dataExtra",
        "onlyView",
    ],
    data() {
        return {
            entities: this.lvEntities,
            entity: {},
            extra: this.dataExtra ?? null,
            show_table: true,
            no_action: this.onlyView ?? false,
            loader: false,
        };
    },
    computed: {
        componentForm() {
            return "form-" + this.entityName;
        },
        service_url() {
            if (!this.urls) {
                return {
                    create: route(this.entityName.concat(".store")),
                    update: decodeURIComponent(
                        route(this.entityName.concat(".update"), ":id")
                    ),
                    delete: decodeURIComponent(
                        route(this.entityName.concat(".destroy"), ":id")
                    ),
                };
            } else {
                return this.urls;
            }
        },
    },
    methods: {
        editItem(selected_entity) {
            this.$scrollTo(this.$refs.form_component.$el);
            this.entity = this.entities.find(
                (ent) => ent.id == selected_entity.id
            );
        },
        createItem(entity) {
            if (this.componentForm != "form-order") {
                this.loaderOn();
            }
            this.createCall(entity, this.service_url.create)
                .then((result) => {
                    this.manageResponse(result);
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        updateItem(entity) {
            if (this.componentForm != "form-order") {
                this.loaderOn();
            }

            this.updateCall(entity, this.service_url.update)
                .then((result) => {
                    this.manageResponse(result);
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        deleteItem(entity) {
            this.deleteCall(entity, this.service_url.delete)
                .then((result) => {
                    this.entity = {};
                    this.entities = result.data.data;
                })
                .catch((error) => {});
        },
        resetForm() {
            this.entity = {};
            this.table_display = true;
        },
        tableDisplay(show) {
            this.show_table = show;
        },
        manageResponse(result) {
            this.loader ? this.loader.hide() : null;
            this.entities = result.data.data;
            this.$refs.form_component.resetForm();
        },
        catchResponse(error) {
            this.loader ? this.loader.hide() : null;
            if (error.status == 422) {
                console.log(error.data.error.errors);
                this.$refs.form_component.$refs.formObs.setErrors(
                    error.data.error.errors
                );
            } else if (error.status == 400) {
                this.$refs.form_component.loader.hide();
            }
        },
        loaderOn() {
            this.loader = this.showLoader(this.$refs.form_component.$el);
        },
    },
};
</script>
