<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: 9
      }"
      :search-options="{
        enabled: true
      }"
      styleClass="vgt-table bordered"
      compactMode
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'price'">
          <currency-input
            :value="parseFloat(props.row.price)"
            @change="value = $event"
            @blur="handleBlur($event, props.row)"
            v-on:keyup.enter="
              handleBlur($event, props.row)
              nextRow($event)
            "
            class="currency_input" 
          />
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
export default {
  name: 'pricelist-table',
  props: ['headers', 'entities'],
  computed: {
    rows: function () {
      return this.entities ?? []
    },
    columns: function () {
      if (this.headers) {
        let head = this.headers.find((h) => h.field === 'price')
        if (head) {
          head.type = 'number'
          head.width = '10%'
        }
        return this.headers
      }
      return []
    }
  },
  methods: {
    handleBlur: function (event, params) {
      let event_price = !_.isEmpty(event.target.value)
        ? parseFloat(event.target.value.replace(/,/g, '.'))
        : null

      if (!_.isEmpty(event_price) || event_price != params.price) {
        params.price = event_price
        this.$emit('editPrice', params)
      }
    },
    nextRow(event) {
      if (event.target.closest('tr').nextSibling.nodeName == 'TR') {
        event.target
          .closest('tr')
          .nextSibling.getElementsByTagName('INPUT')[0]
          .focus() ?? null
      }
    }
  }
}
</script>
