var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow mb-4 col-md-6"},[_c('a',{ref:"accordionPanel",staticClass:"d-block card-header py-3",class:{ disabled: _vm.addDisabled },attrs:{"data-toggle":"collapse","role":"button","aria-expanded":this.accordion.aria,"aria-controls":"new-product"},on:{"click":function($event){$event.preventDefault();return _vm.enableAdd()}}},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[(!_vm.fresh_product.id)?_c('span',[_vm._v("Add New")]):_c('span',[_vm._v("Modify")]),_vm._v(" Product\n        ")])]),_vm._v(" "),_c('div',{staticClass:"collapse",class:this.accordion.collapse,attrs:{"id":"new-product"}},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formObs",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"main-form",attrs:{"id":"product-form","role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processForm)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Name")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.name),expression:"fresh_product.name"},{name:"uppercase",rawName:"v-uppercase"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },attrs:{"type":"text","placeholder":"nome"},domProps:{"value":(_vm.fresh_product.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_product, "name", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Ean Code")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"ean_code","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.ean_code),expression:"fresh_product.ean_code"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },attrs:{"type":"number","placeholder":"EAN CODE"},domProps:{"value":(_vm.fresh_product.ean_code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_product, "ean_code", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Code")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.code),expression:"fresh_product.code"},{name:"uppercase",rawName:"v-uppercase"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },attrs:{"placeholder":_vm.product_code,"type":"text","data-vv-validate-on":"input"},domProps:{"value":(_vm.fresh_product.code)},on:{"dblclick":_vm.assignPlaceHolderToValue,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_product, "code", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Category")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.category_id),expression:"category_id"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.category_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v("\n                                        Please select one\n                                    ")]),_vm._v(" "),_vm._l((_vm.categories),function(c){return _c('option',{key:c.id,domProps:{"value":c.id}},[_vm._v("\n                                        "+_vm._s(c.name)+"\n                                    ")])})],2),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-row"},[(_vm.category.has_frame)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Frame color")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"frame","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.frame_id),expression:"frame_id"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.frame_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":""}},[_vm._v("\n                                        Please select one\n                                    ")]),_vm._v(" "),_vm._l((_vm.framelist),function(f){return _c('option',{key:f.id,domProps:{"value":f.id}},[_vm._v("\n                                        "+_vm._s(f.name)+"\n                                    ")])})],2),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_vm._v(" "),(_vm.category.has_lens)?_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Lens color")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lens","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.lens_id),expression:"lens_id"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.lens_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":""}},[_vm._v("\n                                        Please select one\n                                    ")]),_vm._v(" "),_vm._l((_vm.lenslist),function(l){return _c('option',{key:l.id,domProps:{"value":l.id}},[_vm._v("\n                                        "+_vm._s(l.name)+"\n                                    ")])})],2),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Quantity")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"quantity","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.qty),expression:"fresh_product.qty"}],staticClass:"form-control",class:{
                                                    'border-danger':
                                                        classes.failed,
                                                },attrs:{"disabled":_vm.fresh_product.preorder ==
                                                    1},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fresh_product, "qty", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"selected":""}},[_vm._v("\n                                                    Please select one\n                                                ")]),_vm._v(" "),_c('option',{domProps:{"value":null}},[_vm._v("\n                                                    Not Managed\n                                                ")]),_vm._v(" "),_c('option',{domProps:{"value":0}},[_vm._v("\n                                                    Out of stock\n                                                ")]),_vm._v(" "),_vm._l((60),function(n){return _c('option',{key:n,domProps:{"value":n}},[_vm._v("\n                                                    "+_vm._s(n)+"\n                                                ")])})],2),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"col-md-5"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Volume")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"volume","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(
                                                    _vm.fresh_product.volume
                                                ),expression:"\n                                                    fresh_product.volume\n                                                "}],staticClass:"form-control",class:{
                                                    'border-danger':
                                                        classes.failed,
                                                },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.fresh_product, "volume", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":1}},[_vm._v("\n                                                    0.1\n                                                ")]),_vm._v(" "),_c('option',{domProps:{"value":10}},[_vm._v("\n                                                    1\n                                                ")]),_vm._v(" "),_c('option',{domProps:{"value":100}},[_vm._v("\n                                                    10\n                                                ")])]),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Suggested Price")]),_vm._v(" "),_c('currency-input',{staticClass:"currency_input form-control",model:{value:(
                                            _vm.fresh_product.suggested_price
                                        ),callback:function ($$v) {_vm.$set(_vm.fresh_product, "suggested_price", $$v)},expression:"\n                                            fresh_product.suggested_price\n                                        "}})],1),_vm._v(" "),(
                                        !_vm.preorder && !_vm.fresh_product.preorder
                                    )?_c('div',{staticClass:"col-md-2"},[_c('span')]):_vm._e(),_vm._v(" "),(!_vm.preorder)?_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Best Seller")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"best_seller","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                _vm.fresh_product.best_seller
                                            ),expression:"\n                                                fresh_product.best_seller\n                                            "}],staticClass:"form-control chk-best-seller",class:{
                                                'border-danger':
                                                    classes.failed,
                                            },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                                                _vm.fresh_product.best_seller
                                            )?_vm._i(
                                                _vm.fresh_product.best_seller
                                            ,null)>-1:(
                                                _vm.fresh_product.best_seller
                                            )},on:{"change":function($event){var $$a=
                                                _vm.fresh_product.best_seller
                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fresh_product, "best_seller", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fresh_product, "best_seller", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fresh_product, "best_seller", $$c)}}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',{staticClass:"col-md-2"},[_c('span')]),_vm._v(" "),(
                                        _vm.preorder || _vm.fresh_product.preorder
                                    )?_c('div',{staticClass:"form-group col-md-3"},[_c('label',[_vm._v("Pre-order")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"pre_order","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.preorder),expression:"fresh_product.preorder"}],staticClass:"form-control chk-best-seller",class:{
                                                'border-danger':
                                                    classes.failed,
                                            },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fresh_product.preorder)?_vm._i(_vm.fresh_product.preorder,null)>-1:(_vm.fresh_product.preorder)},on:{"change":function($event){var $$a=_vm.fresh_product.preorder,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fresh_product, "preorder", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fresh_product, "preorder", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fresh_product, "preorder", $$c)}}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"form-group col-md-5 offset-md-1"},[(_vm.fresh_product.image)?_c('img',{staticClass:"w-75 d-block img-thumbnail",attrs:{"src":_vm.img_thumb}}):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-9 offset-md-3"},[_c('label',[_vm._v("Image Url")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"image url","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.image),expression:"fresh_product.image"}],staticClass:"form-control",class:{
                                        'border-danger': classes.failed,
                                    },attrs:{"type":"text","placeholder":"image url"},domProps:{"value":(_vm.fresh_product.image)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_product, "image", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-row mb-3"},[_c('div',{staticClass:"form-group col-md-11"},[_c('label',[_vm._v("Gallery Images")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('p',[_c('small',[_vm._v("Get product's gallery from website.")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-circle btn-sm",attrs:{"type":"button","disabled":!_vm.fresh_product.ean_code},on:{"click":_vm.findProductGallery}},[_c('i',{staticClass:"fas fa-download"})])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"gallery urls","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ classes, errors }){return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.wp_gallery),expression:"wp_gallery"}],staticClass:"form-control",class:{
                                                'border-danger':
                                                    classes.failed,
                                            },attrs:{"rows":"6","wrap":"off"},domProps:{"value":(_vm.wp_gallery)},on:{"input":function($event){if($event.target.composing)return;_vm.wp_gallery=$event.target.value}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row py-3"},[(_vm.wp_gallery)?_c('div',{staticClass:"col d-flex justify-content-around"},_vm._l((_vm.gallery_thumbs),function(t,index){return _c('div',{key:index,staticClass:"align-items-end p-2"},[(t)?_c('img',{staticClass:"img-fluid img-thumbnail rounded",attrs:{"src":t,"alt":"Thumbnail"}}):_vm._e()])}),0):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_product.id),expression:"fresh_product.id"}],attrs:{"hidden":""},domProps:{"value":(_vm.fresh_product.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_product, "id", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")]),_vm._v(" "),_c('div',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("\n                            Cancel\n                        ")])])])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }