<template>
    <div class="row">
        <div class="col-md-5">
            <multiselect
                :options="resources"
                :searchable="true"
                :close-on-select="true"
                track-by="name"
                label="name"
                placeholder="Select Retailer"
                @input="updateSelected"
            ></multiselect>
        </div>
        <div class="offset-md-2 col-md-5">
            <ul class="resources-list">
                <li
                    class="resource-item"
                    v-for="(resource, index) in selectedResources"
                    :data-index="index"
                >
                    <div class="resource-info">
                        <div class="resource-title" :id="index">
                            <span>{{ resource.name }}</span>
                        </div>
                    </div>
                    <div
                        class="delete-controls"
                        v-on:click.prevent="removeDependency(index)"
                    >
                        <i class="fa fa-times fa-fw"></i>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
    components: { Multiselect },
    props: ["options", "selected"],
    name: "multipicker",
    data() {
        return {
            rawResources: this.options,
            resources: Object.assign([], this.options),
            selectedResources: [],
        };
    },
    methods: {
        updateSelected(value) {
            this.selectedResources.push(value);
            this.resources = this.resources.filter((x) => x.id != value.id);
        },
        removeDependency(index) {
            let removeItem = this.selectedResources.at(index);
            this.selectedResources.splice(index, 1);
            let ixRemoveItem = this.rawResources.findIndex(
                (x) => x.id == removeItem.id
            );
            this.resources.splice(ixRemoveItem, 0, removeItem);
        },
    },
    watch: {
        selected(newVal) {
            this.selectedResources = this.selected ?? [];
        },
    },
};
</script>
<style>
.resources-list {
    display: flex;
    margin-top: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 20px;
    padding: 0;
    list-style: none;
}

.resources-list li {
    display: flex;
    align-items: center;
    min-height: 50px;
    padding: 10px 40px 10px 0;
    position: relative;
}

.resources-list li:last-child {
    border-bottom: none;
}

.resources-list li .resource-title {
    font-size: 1em;
    color: #333;
}

.resources-list li .version {
    opacity: 0.7;
    margin-left: 5px;
    font-size: 75%;
}

.resources-list li .resource-description,
.resources-list li .resource-url {
    font-size: 0.8em;
    color: #999;
    margin-top: 5px;
}

.resources-list li .resource-url {
    margin-top: 0;
}

.resources-list li .resource-description:empty {
    display: none;
}

.resources-list li .delete-controls {
    position: absolute;
    right: 0;
    width: 40px;
    text-align: center;
    color: #999;
    cursor: pointer;
}

.resources-list li .delete-controls:hover,
.resources-list li .delete-controls:focus {
    color: red;
}
</style>
