<template>
  <div class="card shadow mb-4 col-md-6">
    <!-- Card Header - Accordion -->
    <a
      :class="{ disabled: addDisabled }"
      @click.prevent="enableAdd()"
      ref="accordionPanel"
      class="d-block card-header py-3"
      data-toggle="collapse"
      role="button"
      :aria-expanded="this.accordion.aria"
      aria-controls="new-fc"
    >
      <h6 class="m-0 font-weight-bold text-primary">
        <span v-if="!fresh_fc.id">Add New</span><span v-else>Modify</span> Frame
        Color
      </h6>
    </a>
    <!-- Card Content - Collapse -->
    <div :class="this.accordion.collapse" class="collapse" id="new-framecolor">
      <div class="card-body">
        <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
          <form
            id="framecolor-form"
            @submit.prevent="handleSubmit(processForm)"
            class="main-form"
            role="form"
          >
            <div class="form-group">
              <label>Name</label>
              <ValidationProvider
                name="name"
                rules="required"
                v-slot="{
                  classes,
                  errors,
                }"
              >
                <input
                  v-model="fresh_fc.name"
                  type="text"
                  class="form-control"
                  :class="{ 'border-danger': classes.failed }"
                  v-uppercase
                />
                <i
                  v-show="errors[0]"
                  class="fas fa-exclamation-circle text-danger"
                ></i>
                <span class="help text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                  <label>Code</label>
                  <ValidationProvider
                    name="code"
                    rules="required"
                    v-slot="{
                      classes,
                      errors,
                    }"
                  >
                    <input
                      v-model="fresh_fc.code"
                      type="text"
                      class="form-control"
                      :class="{ 'border-danger': classes.failed }"
                      v-uppercase
                    />
                    <i
                      v-show="errors[0]"
                      class="fas fa-exclamation-circle text-danger"
                    ></i>
                    <span class="help text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col-md-6">
                  <label>Color</label>
                  <ValidationProvider
                    name="color"
                    rules="required"
                    v-slot="{
                      classes,
                      errors,
                    }"
                  >
                    <input
                      v-model="fresh_fc.color"
                      type="text"
                      class="form-control"
                      :class="{ 'border-danger': classes.failed }"
                      v-uppercase
                    />
                    <i
                      v-show="errors[0]"
                      class="fas fa-exclamation-circle text-danger"
                    ></i>
                    <span class="help text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
            </div>
            <div class="form-group">
              <input hidden v-model="fresh_fc.id" />
              <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
              <button class="btn btn-primary">Save</button>
              <div @click="resetForm()" class="btn btn-danger">Cancel</div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["entity"],
  data() {
    return {
      fresh_fc: {},
      accordion: {
        aria: false,
        collapse: "",
      },
      addDisabled: false,
    };
  },
  watch: {
    entity(newVal) {
      if (this.entity.id) {
        this.addDisabled = true;
        this.accordion.aria = true;
        this.accordion.collapse = "show";
      }
      this.fresh_fc = Object.assign({}, this.entity);
    },
  },
  methods: {
    resetForm() {
      this.accordion.aria = false;
      this.accordion.collapse = "";
      this.addDisabled = false;
      this.$refs.formObs.reset();
      this.$emit("resetForm");
    },
    enableAdd() {
      if (!this.accordion.aria && this.accordion.collapse == "") {
        this.accordion.aria = true;
        this.accordion.collapse = "show";
      } else {
        this.resetForm();
      }
    },
    processForm() {
      this.$refs.formObs.validate().then((result) => {
        if (this.entity.id) {
          this.$emit("updateItem", this.fresh_fc);
        } else {
          this.$emit("createItem", this.fresh_fc);
        }
      });
    },
  },
};
</script>
