<template>
    <div class="col col-md-12">
        <div class="col col-md-8">
            <div class="card shadow mb-4 col-md-12">
                <!-- Card Header - Accordion -->
                <a
                    :class="{ disabled: addDisabled }"
                    @click.prevent="enableAdd()"
                    ref="accordionPanel"
                    class="d-block card-header py-3"
                    data-toggle="collapse"
                    role="button"
                    :aria-expanded="this.accordion.aria"
                    aria-controls="new-user"
                >
                    <h6 class="m-0 font-weight-bold text-primary">
                        <span v-if="!fresh_user.id">Add New</span
                        ><span v-else>Modify</span> User
                    </h6>
                </a>
                <!-- Card Content - Collapse -->
                <div
                    :class="this.accordion.collapse"
                    class="collapse"
                    id="new-user"
                >
                    <div class="card-body">
                        <ValidationObserver
                            ref="formObs"
                            v-slot="{ handleSubmit }"
                        >
                            <form
                                id="user-form"
                                @submit.prevent="handleSubmit(processForm)"
                                class="main-form"
                                role="form"
                            >
                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Name</label>
                                        <ValidationProvider
                                            name="name"
                                            rules="required"
                                            v-slot="{ classes, errors }"
                                        >
                                            <input
                                                v-model="fresh_user.name"
                                                type="text"
                                                class="form-control"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                                v-lowercase
                                            />
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        class="form-group col-md-6"
                                        v-if="is_admin"
                                    >
                                        <label>Price List</label>
                                        <ValidationProvider
                                            name="pricelist"
                                            rules="required"
                                            v-slot="{ classes, errors }"
                                        >
                                            <select
                                                v-model="pricelist_id"
                                                class="form-control"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                            >
                                                <option selected disabled>
                                                    Please select one
                                                </option>
                                                <option
                                                    v-for="p in pricelists"
                                                    v-bind:key="p.id"
                                                    v-bind:value="p.id"
                                                >
                                                    {{ p.name }}
                                                </option>
                                            </select>
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-md-6">
                                        <label>Email</label>
                                        <ValidationProvider
                                            name="email"
                                            rules="required|email"
                                            v-slot="{ classes, errors }"
                                        >
                                            <input
                                                v-model="fresh_user.email"
                                                :disabled="fresh_user.id"
                                                type="email"
                                                class="form-control"
                                                :class="{
                                                    'border-danger':
                                                        classes.failed,
                                                }"
                                                v-lowercase
                                            />
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        class="form-group col-md-4"
                                        v-if="is_admin"
                                    >
                                        <label for="tag">Tag</label>
                                        <ValidationProvider
                                            name="tag"
                                            rules="required"
                                            v-slot="{ classes, errors }"
                                        >
                                            <TagSelect
                                                v-model="
                                                    fresh_user.profile.tag_user
                                                "
                                                :selected="
                                                    fresh_user.profile.tag_user
                                                "
                                                ref="cmp_tag_user"
                                                :options="usertags"
                                                @updateTag="updateTag"
                                            />
                                            <i
                                                v-show="errors[0]"
                                                class="fas fa-exclamation-circle text-danger"
                                            ></i>
                                            <span class="help text-danger">{{
                                                errors[0]
                                            }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div
                                        class="form-group col-md-2 pt-4 mt-3"
                                        v-if="is_admin"
                                    >
                                        <label for="shipping"
                                            >Has Shipping</label
                                        >
                                        <input
                                            id="shipping"
                                            type="checkbox"
                                            v-model="shipping"
                                        />
                                    </div>
                                </div>
                                <profile-form
                                    :entity="fresh_user"
                                    :is_admin="is_admin"
                                    ref="profileForm"
                                    :extra="extra"
                                ></profile-form>
                                <div class="form-group">
                                    <input hidden v-model="fresh_user.id" />
                                    <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
                                    <button class="btn btn-primary">
                                        Save
                                    </button>
                                    <div
                                        @click="resetForm()"
                                        class="btn btn-danger"
                                    >
                                        Cancel
                                    </div>
                                </div>
                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-offset-4 col-md-2">
            <a href="#" @click="exportUsers">
                <div class="card-body p-1 px-2">
                    <span class="mr-1 text-muted">Export Users</span
                    ><img
                        class="icon m-1"
                        :src="asset('images/excel.png')"
                        alt="Download Excel"
                    />
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import TagSelect from "../common/Select.vue";
import ProfileForm from "./ProfileForm.vue";
import FileSaver from "file-saver";

export default {
    components: { TagSelect, ProfileForm },
    props: ["entity", "extra"],
    data() {
        return {
            fresh_user: {
                profile: {
                    tag_user: {},
                    same_address: false,
                },
            },
            accordion: {
                aria: false,
                collapse: "",
            },
            addDisabled: false,
            shipping: true,
            pricelists: this.extra.pricelists,
            pricelist_id: null,
            usertags: this.extra.tag_users,
            is_admin: this.extra.is_admin,
            new_tag: false,
            tag_id: null,
            selected_tag: {},
        };
    },
    computed: {
        // rules() {
        //   return !is_admin ?? 'required'
        // }
    },
    created() {
        if (!_.isEmpty(this.entity)) {
            this.fresh_user = _.cloneDeep(this.entity);
            this.accordion.aria = true;
            this.accordion.collapse = "show";
        }
    },
    watch: {
        entity(newVal) {
            if (this.entity.id) {
                this.addDisabled = true;
                this.accordion.aria = true;
                this.accordion.collapse = "show";
                if (this.is_admin) {
                    this.pricelist_id = this.entity.pricelist.id;
                    this.tag_id = this.entity.tag_user;
                    this.shipping = this.entity.profile.shipping;
                }
            }
            //this.fresh_user = Object.assign({}, this.entity)
            this.fresh_user = _.cloneDeep(this.entity);

            if (_.isEmpty(newVal)) {
                this.fresh_user = {
                    profile: { same_address: false, shipping: true },
                };
            }
        },
        pricelist_id: function (val) {
            if (_.isEmpty(this.entity)) {
                Vue.set(this.fresh_user, "pricelist", val);
            }
            this.fresh_user.pricelist =
                this.pricelists.find((item) => item.id === val) || {};
        },
        tag_id: function (val) {
            // if (_.isEmpty(this.entity)) {
            Vue.set(this.fresh_user.profile, "tag_id", val);
            // }
        },
    },
    methods: {
        resetForm() {
            if (this.$parent.$options.name != "profile-component") {
                this.accordion.aria = false;
                this.accordion.collapse = "";
                this.addDisabled = false;
                this.$refs.formObs.reset();
                this.pricelist_id = null;
                this.shipping = true;
                this.$emit("resetForm");
            } else {
                this.fresh_user = _.cloneDeep(this.entity);
            }
        },
        enableAdd() {
            if (!this.accordion.aria && this.accordion.collapse == "") {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            } else {
                this.resetForm();
            }
        },
        updateTag(value, noOption) {
            this.fresh_user.profile.tag_user = value;
            if (value !== null) {
                if (value.hasOwnProperty("id")) {
                    this.tag_id = value.id;
                    this.new_tag = false;
                } else {
                    this.new_tag = true;
                }
            }
        },
        async exportUsers() {
            let response = await this.blobFileData(route("users.export"));
            // Extract filename from Content-Disposition header
            const contentDisposition = response.headers["content-disposition"];
            let filename = "downloaded_file"; // default filename

            if (contentDisposition) {
                const match = contentDisposition.match(/filename=([^;]+)/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }
            FileSaver.saveAs(response.data, filename);
        },
        async processForm() {
            await this.createNewTag();

            this.fresh_user.profile = this.$refs["profileForm"].profile;

            if (this.fresh_user.profile.same_address) {
                this.fresh_user.profile.ship_address =
                    this.fresh_user.profile.bill_address;
                this.fresh_user.profile.ship_town =
                    this.fresh_user.profile.bill_town;
                this.fresh_user.profile.ship_province =
                    this.fresh_user.profile.bill_province;
                this.fresh_user.profile.ship_zip =
                    this.fresh_user.profile.bill_zip;
                this.fresh_user.profile.ship_country =
                    this.fresh_user.profile.bill_country;
            }

            if (this.is_admin) {
                this.fresh_user.profile.shipping = this.shipping;

                Vue.set(this.fresh_user.profile, "tag_id", this.tag_id);
            }

            this.$refs.formObs.validate().then((result) => {
                if (this.entity.id) {
                    this.$emit("updateItem", this.fresh_user);
                } else {
                    this.$emit("createItem", this.fresh_user);
                }
            });
        },

        createNewTag() {
            if (this.new_tag) {
                return new Promise((resolve) => {
                    this.createCall(
                        this.fresh_user.profile.tag_user,
                        route("tag_user.store")
                    )
                        .then((result) => {
                            this.tag_id = result.data.id;
                            resolve(result.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                });
            }
            return;
        },
    },
};
</script>
