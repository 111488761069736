<template>
  <div class="card-body">
    <data-order
      v-if="order"
      ref="dataOrder"
      :order="order"
      :extra="extra"
      v-on="$listeners"
    ></data-order>
    <div class="form-group col-md-4">
      <label>Category</label>
      <!-- <ValidationProvider
        name="category"
        rules="required"
        :skipIfEmpty="false"
        v-slot="{ classes, errors }"
      >
      :class="{
          'border-danger': classes.failed
        }"-->
      <select class="form-control" v-model.lazy="category">
        <option :value="null" disabled>Select Category</option>
        <option
          v-for="c in categories"
          v-bind:key="c.id"
          v-bind:value="c"
        >
          {{ c.name }}
        </option>
      </select>
      <!-- <i
          v-show="errors[0]"
          class="fas fa-exclamation-circle text-danger"
        ></i>
        <span class="help text-danger">{{ errors[0] }}</span>
      </ValidationProvider> -->
    </div>
    <modal
      ref="modalOrder"
      v-show="isModalVisible"
      @close="closeModal"
    >
      <template v-slot:header>
        <h4 class="modal-title">Save Order</h4>
      </template>
      <template v-slot:body>
        <ValidationObserver ref="formModal">
          <form id="modal-form" class="main-form" role="form">
            <div class="form-group">
              <label for="note">Add note to order:</label>
              <ValidationProvider
                name="Note"
                rules=""
                v-slot="{ classes, errors }"
              >
                <textarea
                  class="form-control"
                  rows="3"
                  v-model="note"
                  :class="{
                    'border-danger': classes.failed
                  }"
                ></textarea>
                <i
                  v-show="errors[0]"
                  class="fas fa-exclamation-circle text-danger"
                ></i>
                <span class="help text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <form-profile
              v-if="isModalVisible && !is_agent"
              :entity="profile"
              :extra="extra"
              :is_admin="is_admin"
              ref="profileForm"
            ></form-profile>
          </form>
        </ValidationObserver>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-secondary"
          @click="closeModal"
        >
          Modify Products
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="saveOrder"
        >
          Save Order
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import DataOrder from './DataOrder.vue'

export default {
  //   inject: ['$validator'],
  name: 'new-order',
  props: ['extra'],
  components: { DataOrder },
  data() {
    return {
      categories: [],
      shipping_cost : [],
      category: null,
      profile: null,
      isModalVisible: false,
      note: null,
      is_admin: false,
      is_agent: false,
      order: null
      //   $_veeValidate: {
      //     value() {
      //       return this.category
      //     }
      //   }
    }
  },
  mounted() {
    this.categories = this.extra.categories    
    this.is_admin = this.extra.is_admin
    this.is_agent = this.extra.is_agent
    this.category = null
    this.shipping_cost = this.extra.shipping_costs
  },
  watch: {
    category(val) {
      this.$emit('loadProducts', val)
    },
  },
  methods: {
    reset() {
      this.closeModal()
      this.category = null
      this.note = null
      if(this.is_admin){
        this.$refs.dataOrder.closeModal()
      }
    },
    async showModal() {
      if (!this.profile) {
        await this.getData(route('user.profile')).then((result) => {
          this.profile = result.data.entity
        })
      }
      this.isModalVisible = true
    },
    closeModal() {
      process.nextTick(() => {
        this.isModalVisible = false
      })
    },
    saveOrder() {
      this.$emit('createOrder')
    }
  }
}
</script>
