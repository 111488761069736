<template>
    <div class="card shadow mb-4 col-md-11">
        <!-- Card Header - Accordion -->
        <a
            @click.prevent="choiceRetailer()"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-order"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                <span v-if="!entity.id">Add New</span>
                <span v-else>View</span> Order
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div :class="this.accordion.collapse" class="collapse" id="new-order">
            <new-order
                v-if="isNewOrder"
                :extra="extra"
                @loadProducts="loadProducts"
                @createOrder="onCreate"
                @statusEnabled="enableStatus"
                ref="newOrder"
            >
            </new-order>
            <update-order
                v-if="!isNewOrder"
                :order="new_ent"
                :extra="order_extra"
                @shipOrder="onShip"
                ref="updateOrder"
            ></update-order>
            <div class="panel panel-default">
                <!-- /.panel-heading -->
                <div class="panel-body">
                    <table-order
                        v-if="isNewOrder"
                        :extra="order_extra"
                        :products_available="products_pl"
                        :shipping_cost="shipping_costs"
                        :product_subscribed="product_subscribed"
                        @onDisable="enableSave"
                        ref="tableOrder"
                    >
                    </table-order>
                </div>
            </div>
            <div class="panel panel-default pl-2 pb-4 d-print-none">
                <!-- /.panel-heading -->
                <div class="panel-body">
                    <!--change for admin -->
                    <button
                        ref="btn_save_order"
                        v-show="isNewOrder"
                        class="btn btn-primary"
                        :disabled="disabled"
                        @click="processForm"
                    >
                        Save
                    </button>
                    <div @click="resetForm()" class="btn btn-danger">
                        {{ cancelTxt }}
                    </div>
                </div>
            </div>
        </div>
        <modal ref="modalRetailer" v-show="isModalVisible" @close="closeModal">
            <template v-slot:header>
                <h4 class="modal-title">Choose Retailer</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="formModal">
                    <form id="modal-form" class="main-form" role="form">
                        <div class="form-group">
                            <label for="retailer">Retailers:</label>
                            <ValidationProvider
                                name="retailer"
                                rules="required"
                                v-slot="{ classes, errors }"
                            >
                                <select
                                    v-model="retailer"
                                    class="form-control"
                                    :class="{
                                        'border-danger': classes.failed,
                                    }"
                                >
                                    <option disabled value="">
                                        Please select one
                                    </option>
                                    <option
                                        v-for="r in retailers"
                                        v-bind:key="r.id"
                                        v-bind:value="r.id"
                                    >
                                        {{ r.name }}
                                    </option>
                                </select>
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                    </form>
                </ValidationObserver>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="closeModal"
                >
                    Close
                </button>
                <button
                    type="button"
                    :disabled="isButtonDisabled"
                    class="btn btn-primary"
                    @click="newOrder"
                >
                    New Order
                </button>
            </template>
        </modal>
    </div>
</template>
<script>
import NewOrder from "../order/NewOrder.vue";
import UpdateOrder from "../order/UpdateOrder.vue";
import TableOrder from "../order/TableOrder.vue";

export default {
    props: ["entity", "extra"],
    components: { NewOrder, UpdateOrder, TableOrder },
    //   provide() {
    //     return {
    //       $validator: this.$validator
    //     }
    //   },
    data() {
        return {
            new_ent: {},
            accordion: {
                aria: false,
                collapse: "",
            },
            addDisabled: false,
            is_agent: this.extra.is_agent,
            is_admin: false,
            products_pl: {},
            disabled: true,
            cancelTxt: "Cancel",
            shipping_costs: false,
            product_subscribed: [],
            order_extra: {},
            loader: null,
            isModalVisible: false,
            retailers: this.extra.retailers,
            retailer: "",
        };
    },
    mounted() {
        this.order_extra = this.extra;
        this.addDisabled = this.is_agent;
        this.disabled = !this.is_agent;
        //TODO: get data from extra ajax call
        // this.shipping_costs = this.extra.shipping_costs;
        // this.product_subscribed = this.extra.product_subscribed;
    },
    computed: {
        isNewOrder: function () {
            let b_orders =
                Object.keys(this.entity).length > 0 &&
                this.entity.hasOwnProperty("id")
                    ? true
                    : false;

            let b_is_pending =
                this.entity.hasOwnProperty("status") &&
                this.entity.status.key == "Pending"
                    ? true
                    : false;

            return !b_orders; //|| (this.is_admin && b_is_pending);
        },
        isConfirmed: function () {
            // return this.is_admin &&
            //     this.entity.hasOwnProperty("status") &&
            //     this.entity.status.key == "Confirmed"
            //     ? true
            return false;
        },
        isButtonDisabled: function () {
            return this.retailer === "";
        },
    },
    watch: {
        entity: {
            handler(newVal) {
                if (_.isObject(newVal) && !isNaN(newVal.id)) {
                    this.getData(route("order.show", [newVal.id])).then(
                        (result) => {
                            if (this.isNewOrder && this.is_admin) {
                                this.$emit("onShowTable", false);
                                this.order_extra = result.data.extra;
                                this.shipping_costs =
                                    result.data.extra.shipping_cost;
                                this.$refs.newOrder.categories =
                                    result.data.extra.categories;
                                this.$refs.tableOrder.loadProductFromOrder(
                                    result.data.entity.products
                                );
                                this.$refs.newOrder.order = result.data.entity;
                                this.$refs.tableOrder.po_header =
                                    result.data.extra.tbl_header;
                            } else if (this.isConfirmed && this.is_admin) {
                                this.order_extra = result.data.extra;
                                this.cancelTxt = "Close";
                            } else {
                                this.order_extra.is_admin = this.is_admin ?? false;
                                this.order_extra.tax_rate = result.data.extra.tax_rate;
                                this.cancelTxt = "Close";
                            }
                            this.new_ent = result.data.entity;

                            this.order_extra.is_agent = !_.isEmpty(this.new_ent.agent);

                            this.addDisabled = true;
                            this.accordion.aria = true;
                            this.accordion.collapse = "show";
                        }
                    );
                } else {
                    this.accordion.aria = false;
                    this.accordion.collapse = "";
                    this.addDisabled = false;
                    this.$emit("onShowTable", true);
                    this.new_ent = {};
                }
            },
            deep: true,
        },
    },
    methods: {
        resetForm() {
            process.nextTick(() => {
                this.accordion.aria = false;
                this.accordion.collapse = "";
                this.new_ent = {};
                this.loader ? this.loader.hide() : null;
                if (this.isNewOrder) {
                    this.retailer = "";
                    this.$refs.tableOrder.reset();
                    this.$refs.newOrder.reset();
                }
                this.$emit("resetForm");
            });
        },
        choiceRetailer() {
            if (!this.entity.id && !this.retailer) {
                this.isModalVisible = true;
            }
        },
        async enableAdd() {
            if (
                !this.accordion.aria &&
                this.accordion.collapse == "" &&
                !this.is_admin
            ) {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
                this.addDisabled = this.is_admin;
                if (!this.entity.id && this.retailer) {
                    let urlRetailerData = decodeURIComponent(
                        route("agent-order.new", [this.extra.agent_id, this.retailer])
                    );

                    await this.getData(urlRetailerData)
                        .then((result) => {
                            if (result) {
                                this.extra.categories = result.data.categories;
                                this.extra.shipping_cost =
                                    result.data.shipping_costs;
                                this.extra.is_admin = false;
                                this.extra.tax_rate = result.data.tax_rate;
                                this.order_extra.tax_rate = result.data.tax_rate;
                                this.$refs.newOrder.categories =
                                    result.data.categories;
                                this.$refs.newOrder.shipping_cost =
                                    result.data.shipping_costs;
                                this.$refs.tableOrder.extra.tax_rate = result.data.tax_rate;                              
                                this.shipping_costs = result.data.shipping_costs;
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    //     let urlProductsPreorder = decodeURIComponent(
                    //         route("preorder.products")
                    //     );
                    //     await this.getData(urlProductsPreorder)
                    //         .then((result) => {
                    //             if (result.data.data.entities.length > 0) {
                    //                 this.$refs.tableOrder.po_data =
                    //                     result.data.data.entities;
                    //                 this.$refs.tableOrder.po_header =
                    //                     result.data.data.header;
                    //             }
                    //         })
                    //         .catch((error) => {
                    //             console.log(error);
                    //         });
                }

                this.$emit("onShowTable", false);
            } else {
                this.resetForm();
            }
        },
        processForm() {
            if (!this.new_ent.id) {
                this.$refs.newOrder.showModal();
            } else {
                if (this.isNewOrder) {
                    this.new_ent.products = this.$refs.tableOrder.po_data;
                }

                if (this.new_ent.status.key == "Confirmed") {
                    this.loader = this.showLoader(
                        this.$refs.newOrder.$refs.dataOrder.$refs.modalStatus
                            .$refs.modalContent
                    );
                } else {
                    this.loader = this.showLoader(this.el);
                }
                this.$emit("updateItem", this.new_ent);
            }
        },
        async onCreate() {
            // return
            const isValid =
                await this.$refs.newOrder.$refs.formModal.validate();
            if (isValid) {
                let products = this.$refs.tableOrder.po_data.map((product) => ({
                    id: product.id,
                    qty: product.qty,
                }));

                this.new_ent = {
                    products: products,
                    notes: this.$refs.newOrder.note ?? null,
                    profile: this.$refs.newOrder.profile.profile
                };
                this.loader = this.showLoader(
                    this.$refs.newOrder.$refs.modalOrder.$refs.modalContent
                );
                this.$emit("createItem", this.new_ent);
            }
        },
        async onShip() {
            let params = _.pick(this.$refs.updateOrder.order, [
                "tracking_url",
                "status",
                "message",
            ]);
            let route_ship = route("order.ship", [this.entity.id]);
            this.loader = this.showLoader(
                this.$refs.updateOrder.$refs.dataOrder.$refs.modalStatus.$refs
                    .modalContent
            );
            this.createCall(params, route_ship)
                .then((result) => {
                    this.$refs.updateOrder.$refs.dataOrder.closeModal();
                    this.resetForm();
                    this.$parent.entities = result.data.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteData: function (params) {
            if (confirm("Do you really want to delete?")) {
                this.$emit("onDelete", params);
                this.resetForm();
            }
        },
        loadProducts(val) {
            if (isNaN(val)) {
                let params = [];
                params.push(val.id);
                //if (this.new_ent)
                if (this.new_ent.hasOwnProperty("id")) {
                    params.push(this.new_ent.id);
                }

                this.getData(route("user.products", params)).then((result) => {
                    this.products_pl = result.data;
                    this.category = val;
                });
            }
        },
        enableSave(val) {
            this.disabled = val;
        },
        enableStatus() {
            this.disabled =
                JSON.stringify(this.new_ent.status) ===
                JSON.stringify(this.entity.status);
        },
        async newOrder() {
            this.isModalVisible = false;
            this.enableAdd();
        },
        closeModal() {
            this.isModalVisible = false;
            process.nextTick(() => {
                this.retailer = "";
            });
        },
    },
};
</script>
