<template>
  <div>
    <div class="row">
      <form-pricelist
        ref="form_pricelist"
        :entities="pl"
        :defaultRates="defaultRates"
        @saveItem="saveItem"
        @onDelete="deleteItem"
        @resetForm="resetForm"
        @fillTable="fillTable"
      ></form-pricelist>
    </div>
    <div class="list_table">
      <div class="row">
        <div class="col-lg-12">
          <div class="panel panel-default">
            <!-- /.panel-heading -->
            <div class="panel-body">
              <pricelist-table
                v-show="entities.length > 0"
                :entities="entities"
                :headers="headers"
                @editPrice="editPrice"
              ></pricelist-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['priceLists', 'defaultRates'],  
  data() {

    return {
      headers: [],
      entities: [],
      entity: {},
      pl: this.priceLists
    }
  },
  methods: {
    saveItem(entity) {
      this.loaderOn()
      this.createCall(entity, route('pricelist.store'))
        .then((result) => {
          this.manageResponse(result)
        })
        .catch((error) => {
          console.log(error)
          this.catchResponse(error)
        })
    },
    deleteItem(entity) {
      this.deleteCall(entity, route('pricelist.destroy', [entity.id]))
        .then((result) => {
          this.pl = result.data.data
          this.$refs.form_pricelist.pricelists = this.pl
        })
        .catch((error) => {})
    },
    fillTable(pricelist) {
      this.resetForm()
      if (pricelist && !_.isEmpty(pricelist)) {
        this.getData(route('productpricelist.list', [pricelist.id]))
          .then((result) => {
            this.headers = result.data.headers
            this.entities = result.data.entities
            this.entity = pricelist
          })
          .catch((error) => {})

        if(!pricelist.shipping_cost.length){
          this.$refs.form_pricelist.shipping_rates = _.cloneDeep(this.defaultRates);
        }
        else{
          this.$refs.form_pricelist.shipping_rates = _.cloneDeep(pricelist.shipping_cost)
        }

      }
    },
    editPrice(params) {
      params['pricelist_id'] = this.entity.id
      let that = this
      this.createCall(
        params,
        route('productpricelist.saveprice', [params.pricelist_id])
      ).then((result) => {
        let ent = this.entities.find(
          (e) =>
            e.product_id === params.product_id &&
            e.pricelist_id === params.pricelist_id
        )
        if (ent) {
          Object.assign(ent, params)
        }
      })
      // .catch((error) => {
      //   this.catchResponse(error)
      // })
    },
    resetForm() {
      this.headers = []
      this.entities = []
      this.entity = {}
    },
    manageResponse(result) {
      this.loader.hide()
      Object.assign(this.pl, result.data.data)
      this.$refs.form_pricelist.entity =
        !_.isEmpty(this.$refs.form_pricelist.entity) &&
        !_.isString(this.$refs.form_pricelist.entity)
          ? this.priceLists.find(
              (p) => p.id == this.$refs.form_pricelist.entity.id
            )
          : (this.pl.find(
        (p) => p.name == this.$refs.form_pricelist.new_ent.name
      ) ?? {})
      this.$refs.form_pricelist.addDisabled = false
    },
    catchResponse(error) {
      this.loader.hide()
      if ((error.status = 422)) {
        console.log(error.data.error.errors)
        this.$refs.form_pricelist.$refs.formObs.setErrors(
          error.data.error.errors
        )
      }
    },
    loaderOn() {
      this.loader = this.showLoader(this.$refs.form_pricelist.$el)
    }
  }
}
</script>
