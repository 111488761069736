<template>
    <div class="row">
        <div class="col-md">
            <v-select
                ref="tag_user"
                :options="resources"
                :value="selected"
                label="name"
                @input="updateTag"
                @search="checkInput"
                :components="{ Deselect, OpenIndicator }"
            >
                <template #no-options="{ search, searching, loading }">
                    <div class="cnt_add_tag">
                        <button
                            :disabled="!addDisabled"
                            ref="add_tag"
                            class="btn btn-link btn_add_tag"
                            @click.prevent="addTagToList"
                        >
                            <span>+</span> Add Tag to List
                        </button>
                    </div>
                </template>
            </v-select>
        </div>
    </div>
</template>
<script>
import vSelect from "vue-select";

export default {
    components: { vSelect },
    props: ["options", "selected"],
    name: "tag-select",
    data() {
        return {
            rawResources: this.options,
            resources: Object.assign([], this.options),
            selectResource: this.selected,
            addDisabled: false,
            noOption: false,
            Deselect: {
                render: (createElement) =>
                    createElement("i", { class: ["far", "fa-times-circle"] }),
            },
            OpenIndicator: {
                render: (createElement) => createElement("i", { class: ["far", "angle"] }),
            },
        };
    },
    methods: {
        checkInput(value) {
            this.addDisabled = value.length > 3;
        },
        addTagToList() {
            this.resources.push({ name: this.$refs.tag_user.search });
            this.noOption = true;
        },
        updateTag(value) {
            this.noOption = this.$refs.tag_user.search.length > 0;
            this.$emit("updateTag", value, this.noOption);
        },
    },
    // watch: {
    //     selected(newVal) {
    //         this.selected = this.selected ?? null;
    //     }
    // },
};
</script>
