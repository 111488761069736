<template>
    <div class="card shadow mb-4 col-md-6">
        <!-- Card Header - Accordion -->
        <a
            :class="{ disabled: addDisabled }"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-rs"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                Show Detail Waiting Product User
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div
            :class="this.accordion.collapse"
            class="collapse"
            id="new-waiting_restock"
        >
            <div class="card-body">
                <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
                    <form
                        id="waiting_restock-form"
                        @submit.prevent="handleSubmit(processForm)"
                        class="main-form"
                        role="form"
                    >
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label>Product:</label>
                                <b>{{ fresh_rs.product }}</b>
                            </div>
                        </div>
                        <div class="form-row pb-3">
                            <label><strong>Retailers:</strong></label>
                        </div>
                        <div class="form-row pb-2" v-for="user in fresh_rs.user">
                            {{ user.name }}  <span v-if="(user.qty)">&nbsp; qty: <b>{{ user.qty }}</b></span>
                            <span class="ml-2" v-if="(user.qty)">Status: <b>{{ user.status }}</b></span>
                        </div>
                        <div class="form-group pt-4">
                            <input hidden v-model="fresh_rs.id" />
                            <div @click="resetForm()" class="btn btn-danger">
                                Cancel
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["entity"],
    data() {
        return {
            fresh_rs: {},
            accordion: {
                aria: false,
                collapse: "",
            },
            addDisabled: false,
        };
    },
    watch: {
        entity(newVal) {
            if (this.entity.id) {
                this.addDisabled = true;
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            }
            this.fresh_rs = Object.assign({}, this.entity);
        },
    },
    methods: {
        resetForm() {
            this.accordion.aria = false;
            this.accordion.collapse = "";
            this.addDisabled = false;
            this.$refs.formObs.reset();
            this.$emit("resetForm");
        },
        enableAdd() {
            if (!this.accordion.aria && this.accordion.collapse == "") {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            } else {
                this.resetForm();
            }
        },
    },
};
</script>
