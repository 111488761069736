const UpperCase = {
    update(el) {
        const sourceValue = el.value
        const newValue = sourceValue.toUpperCase()

        if (sourceValue !== newValue) {
            el.value = newValue
            el.dispatchEvent(new Event('input', { bubbles: true }))
        }
    }
}

const LowerCase = {
    update(el) {
        const sourceValue = el.value
        const newValue = sourceValue.toLowerCase()

        if (sourceValue !== newValue) {
            el.value = newValue
            el.dispatchEvent(new Event('input', { bubbles: true }))
        }
    }
}

const Capitalize = {
    update(el) {
        const sourceValue = el.value
        const newValue = sourceValue.toCapitalize()

        if (sourceValue !== newValue) {
            el.value = newValue
            el.dispatchEvent(new Event('input', { bubbles: true }))
        }
    }
}

export { UpperCase, LowerCase }
