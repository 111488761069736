const ShowNotification = {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                showMessage(code, message){
                    if(code == 200){
                        Vue.$toast.success(message)
                    }
                    else if( code >= 300 && code != 500){
                        Vue.$toast.warning(message)
                    }
                    else if( code == 500){
                        Vue.$toast.error(message)
                    }
                },
            },
        })
    }
}

export default ShowNotification
