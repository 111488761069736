<template>
  <div class="card-body" v-if="order.id">
    <div class="row">
      <div class="panel panel-default col-md-12">
        <div class="panel-body">
          <data-order :order="order" ref="dataOrder" :extra="extra"></data-order>
          <hr />
          <div class="row">
            <div class="col-md-12 text-left text-capitalize">
              <h5>your products order:</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Category</th>
                      <th scope="col">Name</th>
                      <th scope="col">Code</th>
                      <th scope="col">Qty.</th>
                      <th scope="col" class="text-right mr-1">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(p, index) in order.products"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ p.category }}</td>
                      <td>{{ p.name }}</td>
                      <td>{{ p.code }}</td>
                      <td class="text-center">{{ p.qty }}</td>
                      <td class="text-right">{{ p.price }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr v-if="order.shipping_cost">
                      <td colspan="6">
                        <h6 class="text-right pt-3">
                          Shipping Cost:
                          <span>{{ order.shipping_cost }}</span>
                        </h6>
                      </td>
                    </tr>                   
                    <tr>
                      <td colspan="6">
                        <h6 class="text-right pt-2">
                          Total:
                          <span class="font-weight-bold"
                            >{{ order.amount }}
                          </span>
                        </h6>
                      </td>
                    </tr>
                     <tr v-if="order.amount_taxed">
                      <td colspan="6">
                        <h6 class="text-right pt-2">
                          Total with ({{ extra?.tax_rate?.description || order?.user?.tax_rate?.description }}):
                          <span class="font-weight-bold"
                            >{{ order.amount_taxed }}
                          </span>
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6">
                        <h6 class="d-inline">Note:</h6>
                        <span class="font-weight-light">
                          {{ order.note }}
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataOrder from './DataOrder.vue'

export default {
  name: 'update-order',
  props: ['order', 'extra'],
  components: { DataOrder },
  methods: {
    resetForm() {
      this.$refs.dataOrder.closeModal()
    }
  }
}
</script>
