<template>
    <div class="card shadow mb-4">
        <!-- Card Header - Dropdown -->
        <div
            class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                {{ title }}
            </h6>
            <div class="d-flex align-items-center">
                <div class="mr-2">Date Interval</div>
                <div>
                    <select
                        class="form-control"
                        v-model="selectedRange"
                        @change="changeRange"
                    >
                        <option
                            v-for="option in rangeOptions"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.label }}
                        </option>
                    </select>
                </div>

                <div class="dropdown no-arrow ml-3">
                    <a
                        class="dropdown-toggle"
                        href="#"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i
                            class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"
                        ></i>
                    </a>
                    <div
                        class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                        aria-labelledby="dropdownMenuLink"
                    >
                        <div class="dropdown-header">Actions:</div>
                        <a
                            class="dropdown-item"
                            role="button"
                            @click="exportToExcel"
                            >Download in Excel
                            <i class="fas fa-solid fa-file-excel"></i
                        ></a>
                        <!-- <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#"
                                >Something else here</a
                            > -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Card Body -->
        <div class="card-body">
            <div class="chart-area">
                <vue-good-table
                    ref="insight_table"
                    :columns="columns"
                    :rows="rows"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                    }"
                    :search-options="{
                        enabled: true,
                    }"
                    :sort-options="{
                        enabled: true,
                        initialSortBy: sortCommand,
                    }"
                    styleClass="vgt-table bordered"
                    compactMode
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'amount'">
                            {{ props.row.amount | currency }}
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>
                </vue-good-table>
            </div>
        </div>
    </div>
</template>

<script>
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export default {
    props: [
        "headers",
        "entityName",
        "entities",
        "action",
        "title",
        "initialSort",
    ],
    data() {
        return {
            selectedRange: "12",
            no_action: true,
            rangeOptions: [
                { label: "Last Year", value: "12" },
                { label: "Last 6 Months", value: "6" },
                { label: "Last 3 Months", value: "3" },
                { label: "Last Month", value: "1" },
                { label: "All Period", value: "" },
            ],
            columns: this.headers,
            rows: [],
        };
    },
    mounted() {
        this.changeRange();
    },
    computed: {
        expLabel() {
            return this.action.replace("-", "_");
        },
        sortCommand() {
            return this.initialSort ? JSON.parse(this.initialSort) : {};
        }
    },
     watch: {
        entities(newVal) {
            this.rows = newVal;
            this.$refs.insight_table.reset();
        },
    },
    methods: {
        async changeRange() {
            this.$emit("loadData", {
                date_interval: this.selectedRange,
                action: this.action,
            });
        },
        exportToExcel() {
            // Create a new workbook and a sheet from the JSON data
            let entities_for_export = this.entities.map((item) => {
                const { id, ...rest } = item;
                return rest;
            });

            const worksheet = XLSX.utils.json_to_sheet(entities_for_export);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Generate a binary string representation of the workbook
            const wbout = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "binary",
            });

            // Convert the binary string to a Blob
            function s2ab(s) {
                const buf = new ArrayBuffer(s.length);
                const view = new Uint8Array(buf);
                for (let i = 0; i < s.length; i++)
                    view[i] = s.charCodeAt(i) & 0xff;
                return buf;
            }

            const blob = new Blob([s2ab(wbout)], {
                type: "application/octet-stream",
            });

            let option = this.rangeOptions.find(
                (option) => option.value === this.selectedRange
            );

            const optionLabel = option.label.replace(" ", "_").toLowerCase();

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            let currentMonth = currentDate.getMonth() + 1;
            currentMonth =
                currentMonth < 10 ? "0" + currentMonth : currentMonth;

            const filename = `${currentMonth}_${currentYear}_${optionLabel}_${this.expLabel}.xlsx`;
            FileSaver.saveAs(blob, filename);
        },
        loadItems(param) {
            this.$emit("loadItems");
        },
    },
};
</script>
