var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow mb-4 col-md-6"},[_c('a',{ref:"accordionPanel",staticClass:"d-block card-header py-3",class:{ disabled: _vm.addDisabled },attrs:{"data-toggle":"collapse","role":"button","aria-expanded":this.accordion.aria,"aria-controls":"new-fc"},on:{"click":function($event){$event.preventDefault();return _vm.enableAdd()}}},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[(!_vm.fresh_fc.id)?_c('span',[_vm._v("Add New")]):_c('span',[_vm._v("Modify")]),_vm._v(" Frame\n      Color\n    ")])]),_vm._v(" "),_c('div',{staticClass:"collapse",class:this.accordion.collapse,attrs:{"id":"new-framecolor"}},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"formObs",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"main-form",attrs:{"id":"framecolor-form","role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.processForm)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({
                classes,
                errors,
              }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_fc.name),expression:"fresh_fc.name"},{name:"uppercase",rawName:"v-uppercase"}],staticClass:"form-control",class:{ 'border-danger': classes.failed },attrs:{"type":"text"},domProps:{"value":(_vm.fresh_fc.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_fc, "name", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Code")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({
                    classes,
                    errors,
                  }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_fc.code),expression:"fresh_fc.code"},{name:"uppercase",rawName:"v-uppercase"}],staticClass:"form-control",class:{ 'border-danger': classes.failed },attrs:{"type":"text"},domProps:{"value":(_vm.fresh_fc.code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_fc, "code", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form-group col-md-6"},[_c('label',[_vm._v("Color")]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({
                    classes,
                    errors,
                  }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_fc.color),expression:"fresh_fc.color"},{name:"uppercase",rawName:"v-uppercase"}],staticClass:"form-control",class:{ 'border-danger': classes.failed },attrs:{"type":"text"},domProps:{"value":(_vm.fresh_fc.color)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_fc, "color", $event.target.value)}}}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"fas fa-exclamation-circle text-danger"}),_vm._v(" "),_c('span',{staticClass:"help text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fresh_fc.id),expression:"fresh_fc.id"}],attrs:{"hidden":""},domProps:{"value":(_vm.fresh_fc.id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.fresh_fc, "id", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary"},[_vm._v("Save")]),_vm._v(" "),_c('div',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("Cancel")])])])]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }