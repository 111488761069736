<template>
    <span>
        <ValidationProvider name="shipping_cost" v-slot="{ classes, errors }">
            <div
                class="panel-heading py-2 font-weight-bold"
                :class="{
                    'text-danger': classes.failed
                }"
            >
                Shipping Cost Rates
                <span class="help text-danger" v-if="!!errors.length">
                    are required</span
                >
            </div>
        </ValidationProvider>

        <div
            class="card bg-danger text-white shadow p-2 mb-3"
            v-if="warn_shipping_cost"
        >
            <div class="card-danger">Shipping Cost Rates Not Saved</div>
        </div>
        <div
            class="formm-group"
            v-for="(ship, key) in shipping_class"
            :key="ship.rate"
            :value="ship"
        >
          <div class="form-row">
              <div class="form-group col-md-5">
                  <label v-if="key != last_key">
                      Number of Products <b>Less Than {{ ship.rate }}</b>
                  </label>
                  <label v-else>
                      Number of Products <b>Greater Than {{ last_rate }}</b>
                  </label>
              </div>
              <div class="form-group offset-md-1">
                  <currency-input
                      v-model="ship.price"
                      :disabled="!add_disabled"
                      class="currency_input"
                  />
              </div>
          </div>
        </div>
    </span>
</template>
<script>
export default {
    props: ["shipping_class", "add_disabled", "warn_shipping_cost"],
    computed: {
        last_rate() {
            return this.shipping_class[this.shipping_class.length - 2].rate;
        },
        last_key() {
            return Object.keys(this.shipping_class).slice(-1)[0];
        }
    }
};
</script>
