<template>
  <div class="card shadow mb-4 col-md-6">
    <!-- Card Header - Accordion -->
    <a
      :class="{ disabled: addDisabled }"
      @click.prevent="enableAdd()"
      ref="accordionPanel"
      class="d-block card-header py-3"
      data-toggle="collapse"
      role="button"
      :aria-expanded="this.accordion.aria"
      aria-controls="new-genuine_code"
    >
      <h6 class="m-0 font-weight-bold text-primary">Import Genuine Codes</h6>
    </a>
    <!-- Card Content - Collapse -->
    <div
      :class="this.accordion.collapse"
      class="collapse"
      id="new-genuine_code"
    >
      <div class="card-body">
        <form
          id="genuine_code-form"
          class="main-form"
          role="form"
          @submit.prevent="processForm()"
        >
          <div class="form-group">
            <label>Genuine Codes File</label>
            <app-fileupload
              ref="upload_cmp"
              @enableImport="enableImport"
            ></app-fileupload>
            <!-- <ValidationProvider
                name="name"
                rules="required"
                v-slot="{
                  classes,
                  errors,
                }"
              >
                <input
                  v-model="fresh_category.name"
                  type="text"
                  class="form-control"
                  :class="{ 'border-danger': classes.failed }"
                />
                <i
                  v-show="errors[0]"
                  class="fas fa-exclamation-circle text-danger"
                ></i>
                <span class="help text-danger">{{ errors[0] }}</span>
              </ValidationProvider> -->
          </div>
          <div class="form-group">
            <!-- <input hidden v-model="fresh_category.id" /> -->
            <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
            <button :disabled="invalid" class="btn btn-primary">Import</button>
            <div @click="resetForm()" class="btn btn-danger">Cancel</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import AppFileupload from "../common/Upload";
import moment from "moment";

export default {
  components: { AppFileupload },
  props: ["entity"],
  data() {
    return {
      accordion: {
        aria: false,
        collapse: "",
      },
      addDisabled: false,
      invalid: true,
      fileImport: null,
    };
  },
  methods: {
    resetForm() {
      this.accordion.aria = false;
      this.accordion.collapse = "";
      this.addDisabled = false;
      this.$refs.upload_cmp.resetForm();
      this.invalid = true;
      this.$emit("resetForm");
    },
    enableAdd() {
      if (!this.accordion.aria && this.accordion.collapse == "") {
        this.accordion.aria = true;
        this.accordion.collapse = "show";
      } else {
        this.resetForm();
      }
    },
    enableImport(fileImport) {
      this.fileImport = fileImport;
      this.invalid = false;
    },
    processForm() {
      this.$emit("importItems", this.fileImport);
      this.invalid = true;
    },
    forceDownload(response) {
      
      var lineArray = [];
      response.forEach(function (infoArray, index) {
        var line = infoArray.concat(",");
        lineArray.push(
          index == 0 ? "data:text/csv;charset=utf-8," + line : line
        );
      });
      var csvContent = lineArray.join("\n");

      let now = moment(new Date()).format("YYYY-MM-DD");      

      const nameFile = "codes_" + now + ".csv";

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", nameFile);

      setTimeout(() => link.click(), 2500);
      
      this.resetForm();
    },
  },
};
</script>
