var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card shadow mb-4"},[_c('div',{staticClass:"card-header py-3 d-flex flex-row align-items-center justify-content-between"},[_c('h6',{staticClass:"m-0 font-weight-bold text-primary"},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_vm._v("Date Interval")]),_vm._v(" "),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRange),expression:"selectedRange"}],staticClass:"form-control",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedRange=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.changeRange]}},_vm._l((_vm.rangeOptions),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v("\n                        "+_vm._s(option.label)+"\n                    ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"dropdown no-arrow ml-3"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right shadow animated--fade-in",attrs:{"aria-labelledby":"dropdownMenuLink"}},[_c('div',{staticClass:"dropdown-header"},[_vm._v("Actions:")]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"role":"button"},on:{"click":_vm.exportToExcel}},[_vm._v("Download in Excel\n                        "),_c('i',{staticClass:"fas fa-solid fa-file-excel"})])])])])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"chart-area"},[_c('vue-good-table',{ref:"insight_table",attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                },"search-options":{
                    enabled: true,
                },"sort-options":{
                    enabled: true,
                    initialSortBy: _vm.sortCommand,
                },"styleClass":"vgt-table bordered","compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'amount')?_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("currency")(props.row.amount))+"\n                    ")]):_c('span',[_vm._v("\n                        "+_vm._s(props.formattedRow[props.column.field])+"\n                    ")])]}}])})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-toggle",attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"fas fa-ellipsis-v fa-sm fa-fw text-gray-400"})])
}]

export { render, staticRenderFns }