<template>
    <div class="card shadow mb-4 col-md-8">
        <!-- Card Header - Accordion -->
        <a
            :class="{ disabled: addDisabled }"
            @click.prevent="enableAdd()"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-advice"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                <span v-if="!fresh_advice.id">Add New</span
                ><span v-else>Modify</span> Advice
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div :class="this.accordion.collapse" class="collapse" id="new-advice">
            <div class="card-body">
                <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
                    <form
                        id="advice-form"
                        @submit.prevent="handleSubmit(processForm)"
                        class="main-form"
                        role="form"
                    >
                        <div class="form-group">
                            <label>Advice Message</label>
                            <ValidationProvider
                                name="message"
                                rules="required"
                                v-slot="{ classes, errors }"
                            >
                                <textarea
                                    v-model="fresh_advice.message"
                                    rows="6"
                                    class="form-control col-md-6"
                                    :class="{ 'border-danger': classes.failed }"
                                >
                                </textarea>
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-12">
                                <div class="col-md-12 pb-4">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <label>Prices Lists</label>
                                        </div>
                                        <div class="col-md-2">
                                            <span>
                                                <button
                                                    id="toggleAll"
                                                    class="btn btn-warning"
                                                    data-toggle="false"
                                                    @click="toggle"
                                                >
                                                    {{ text_toggle }}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div
                                            v-for="item in prices_list"
                                            class="form-group col-md-4"
                                        >
                                            <input
                                                type="checkbox"
                                                :value="item.id"
                                                name="list_name"
                                                :id="item.name"
                                                v-model="
                                                    fresh_advice.pricelists
                                                "
                                            />
                                            <label
                                                class="checkbox-label"
                                                :for="item.name"
                                            >
                                                {{ item.name }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <input hidden v-model="fresh_advice.id" />
                            <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
                            <button class="btn btn-primary">Save</button>
                            <div @click="resetForm()" class="btn btn-danger">
                                Cancel
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["entity", "extra"],
    data() {
        return {
            fresh_advice: {
                pricelists: [],
            },
            accordion: {
                aria: false,
                collapse: "",
            },
            prices_list: this.extra.price_lists,
            addDisabled: false,
            text_toggle: "Select All",
        };
    },
    watch: {
        entity(newVal) {
            if (this.entity.id) {
                this.fresh_advice.pricelists = this.entity.pricelist;

                if (
                    this.extra.price_lists.length ==
                    this.entity.pricelists.length
                ) {
                    this.text_toggle = "Unselect All";
                    document.getElementById("toggleAll").dataset.toggle = true;
                }

                this.addDisabled = true;
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            }
            this.fresh_advice = Object.assign({}, this.entity);
        },
    },
    methods: {
        resetForm() {
            this.accordion.aria = false;
            this.accordion.collapse = "";
            this.addDisabled = false;
            this.text_toggle = "Select All";
            document.getElementById("toggleAll").dataset.toggle = false;
            this.$refs.formObs.reset();
            this.$emit("resetForm");
        },
        enableAdd() {
            if (!this.accordion.aria && this.accordion.collapse == "") {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            } else {
                this.resetForm();
            }
        },
        processForm() {
            this.$refs.formObs.validate().then((result) => {
                this.$emit("createItem", this.fresh_advice);
            });
        },
        toggle(e) {
            e.preventDefault();
            let toggleAll = document.getElementById("toggleAll");
            let checkedPricelist =
                toggleAll.dataset.toggle === "false" ? true : false;
            toggleAll.dataset.toggle = checkedPricelist;
            let checkboxes = document.getElementsByName("list_name");
            for (var i = 0, n = checkboxes.length; i < n; i++) {
                checkboxes[i].checked = checkedPricelist;
            }

            if (checkedPricelist) {
                this.fresh_advice.pricelists = this.prices_list.map(
                    (a) => a.id
                );
            } else {
                this.fresh_advice.pricelists = [];
            }

            this.text_toggle = checkedPricelist ? "Unselect All" : "Select All";
        },
    },
};
</script>
