<template>
  <div>
    <div class="row">
      <form-user
        ref="form_component"
        :entity="entity"
        :extra="extra"
        @updateItem="updateItem"
      ></form-user>
    </div>
  </div>
</template>
<script>
export default {
  props: ['lvEntity', 'dataExtra'],
  data() {
    return {
      entity: this.lvEntity,
      extra: this.dataExtra ?? null,
      entityName: this.dataExtra
        ? this.dataExtra['entity-name']
        : null
    }
  },
  computed: {
    service_url() {
      if (!this.urls) {
        return {
          create: route(this.entityName.concat('.store')),
          update: decodeURIComponent(
            route(this.entityName.concat('.update'), ':id')
          ),
          delete: decodeURIComponent(
            route(this.entityName.concat('.destroy'), ':id')
          )
        }
      } else {
        return this.urls
      }
    }
  },
  methods: {
    updateItem(entity) {
      let loader = this.showLoader(this.$refs.form_component.$el)
      this.updateCall(entity, this.service_url.update)
        .then((result) => {
          loader.hide()
          this.manageResponse(result)

          //this.entity = result.data
        })
        .catch((error) => {
          loader.hide()
          console.log(error)
          this.catchResponse(error)
        })
    },
    manageResponse(result) {
      this.entity = _.cloneDeep(result.data.data)
    },
    catchResponse(error) {
      if ((error.status = 422)) {
        console.log(error.data.error.errors)
        this.$refs.form_component.$refs.formObs.setErrors(
          error.data.error.errors
        )
      }
    }
  }
}
</script>
