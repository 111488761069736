<template>
    <div>
        <div class="panel panel-default pb-3">
            <div class="panel-heading py-2 font-weight-bold">
                Products Available
            </div>
            <div class="panel-body">
                <div
                    class="form-group col-md-4"
                    v-if="pa_frames && pa_frames.length > 0"
                >
                    <label>Frame Color Filter:</label>
                    <select
                        class="form-control"
                        v-model="frame"
                        @change="changeFrame"
                    >
                        <option :value="null">All Frames</option>
                        <option
                            v-for="c in pa_frames"
                            v-bind:key="c"
                            v-bind:value="c"
                        >
                            {{ c }}
                        </option>
                    </select>
                </div>
                <vue-good-table
                    ref="pa_table"
                    :rows="pa_data"
                    :columns="pa_header"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 5,
                        setCurrentPage: 1,
                    }"
                    :search-options="{
                        enabled: true,
                    }"
                    styleClass="vgt-table bordered"
                    compactMode
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'qty'">
                            <span
                                v-if="props.row.preorder"
                                style="font-weight: bold; color: green"
                                >PRE-ORDER</span
                            >
                            <span
                                v-else-if="props.row.qty == 0"
                                style="font-weight: bold; color: red"
                                >OUT OF STOCK</span
                            >
                            <span
                                v-else
                                v-html="setOptionQuantity(1, props.row.qty)"
                                @change="changeQuantity($event, props.row)"
                            >
                            </span>
                            <button
                                v-if="props.row.preorder"
                                aria-label="Register for pre-order!"
                                data-balloon-pos="up"
                                type="button"
                                :disabled="
                                    product_subscribed.includes(props.row.id)
                                "
                                class="btn btn-success btn-circle btn-sm btn-restock"
                                @click="registerPreorder($event, props.row)"
                            >
                                <i
                                    class="fas fa-regular fa-bookmark fa-fade"
                                ></i>
                            </button>
                            <button
                                v-else-if="props.row.qty == 0"
                                aria-label="Email me when stock available"
                                data-balloon-pos="up"
                                type="button"
                                class="btn btn-warning btn-circle btn-sm btn-restock"
                                @click="restockProduct($event, props.row)"
                                :disabled="
                                    product_subscribed.includes(props.row.id) ||
                                    (!extra.is_admin && extra.is_agent)
                                "
                            >
                                <i class="fas fa-bell"></i>
                            </button>
                        </span>
                        <span
                            v-else-if="props.column.field == 'image'"
                            class="image-gallery"
                            data-featherlight-gallery
                            v-featherlight-gallery
                        >
                            <a
                                :href="parsePath(props.row.image)"
                                role="button"
                            >
                                <img
                                    v-if="props.row.image"
                                    class="w-50 mx-auto d-block"
                                    :src="parsePath(props.row.image, 'thumb')"
                                    :data-fail="parsePath(props.row.image)"
                                    v-image-fall-back
                                />
                            </a>
                            <a
                                v-for="(thumb, index) in props.row.gallery"
                                :href="thumb"
                                :key="index"
                                class="featured"
                            >
                                <img class="d-none" :src="thumb" />
                            </a>
                        </span>
                        <span v-else-if="props.column.field == 'price'">
                            <span
                                class="text-warning lbl-best_seller"
                                v-if="props.row.best_seller"
                                >BEST SELLER</span
                            >
                            {{ props.row.price | currency }}
                            <div
                                class="small text-gray-500"
                                v-if="props.row.suggested_price"
                            >
                                suggested retail price ({{
                                    props.row.suggested_price | currency
                                }})
                            </div>
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                        <span v-if="props.column.field == 'actions'">
                            <button
                                type="button"
                                :class="{ disabled: props.row.qty == 0 }"
                                :disabled="props.row.qty == 0"
                                class="btn btn-success btn-circle btn-sm"
                                @click="addProduct($event, props.row)"
                            >
                                <i class="fas fa-plus"></i>
                            </button>
                        </span>
                    </template>
                </vue-good-table>
            </div>
        </div>
        <hr />
        <div class="panel panel-default pb-4">
            <div class="panel-body py-2">
                <span class="font-weight-bold">Products Order</span>
                <span class="float-right pr-2" v-if="shipping_cost">
                    Shipping Cost: {{ totalVolume | currency }}
                </span>
            </div>
            <div class="panel-body text-right pr-2 pb-2 font-weight-bold">
                Total: {{ totalAmount | currency }}
            </div>
            <div
                v-if="totalAmountTaxed !== totalAmount && extra.tax_rate"
                class="panel-body text-right pr-2 pb-2 font-weight-bold"
            >
                Total with ({{ extra?.tax_rate?.description }} ):
                {{ totalAmountTaxed | currency }}
            </div>

            <div class="panel-body">
                <vue-good-table
                    ref="po_table"
                    :rows="po_data"
                    :columns="po_header"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 9,
                    }"
                    :search-options="{
                        enabled: true,
                    }"
                    styleClass="vgt-table bordered"
                    compactMode
                >
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'price'">
                            {{ (props.row.price * props.row.qty) | currency }}
                            <!-- {{ props.row }} -->
                        </span>
                        <span v-else-if="props.column.field == 'qty'">
                            <span
                                v-if="props.row.qty == 0"
                                style="font-weight: bold; color: red"
                                >OUT OF STOCK</span
                            >
                            <span
                                v-else
                                v-html="
                                    setOptionQuantity(
                                        props.row.qty,
                                        props.row.qty_max
                                    )
                                "
                                @change="changeQuantityOrder($event, props.row)"
                            >
                            </span>
                        </span>
                        <span
                            v-else-if="props.column.field == 'image'"
                            class="image-gallery"
                            data-featherlight-gallery
                            v-featherlight-gallery
                        >
                            <a
                                :href="parsePath(props.row.image)"
                                role="button"
                            >
                                <img
                                    v-if="props.row.image"
                                    class="w-50 mx-auto d-block"
                                    :src="parsePath(props.row.image, 'thumb')"
                                    :data-fail="parsePath(props.row.image)"
                                    v-image-fall-back 
                                />
                            </a>
                            <a
                                v-for="(thumb, index) in props.row.gallery"
                                :href="thumb"
                                :key="index"
                                class="featured"
                            >
                                <img class="d-none" :src="thumb" />
                            </a>
                        </span>
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                        <span v-if="props.column.field == 'actions'">
                            <button
                                type="button"
                                :class="{ disabled: props.row.qty == 0 }"
                                :disabled="props.row.qty == 0"
                                class="btn btn-danger btn-circle btn-sm"
                                @click="removeProduct(props.row)"
                            >
                                <i class="fas fa-minus"></i>
                            </button>
                        </span>
                    </template>
                </vue-good-table>
            </div>
        </div>
        <modal
            ref="modalPreorder"
            v-show="isModalPreorderVisible"
            @close="closeModal"
        >
            <template v-slot:header>
                <h4 class="modal-title">Confirm Pre-order</h4>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="preorderModal">
                    <form id="preorder_form" class="main-form" role="form">
                        <div class="form-group col-md-6 offset-md-3">
                            <label for="preorder-qty"
                                >Select quantity for pre-order:</label
                            >
                            <ValidationProvider
                                name="preorder-qty"
                                rules="required"
                                v-slot="{ classes, errors }"
                            >
                                <input
                                    type="number"
                                    class="form-control"
                                    v-model="preorder_qty"
                                    :class="{
                                        'border-danger': classes.failed,
                                    }"
                                />
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                        <input hidden v-model="preorder_obj" />
                    </form>
                </ValidationObserver>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-secondary"
                    @click="closeModal"
                >
                    Cancel
                </button>
                <button class="btn btn-primary" @click="clickModal">
                    Pre-order
                </button>
            </template>
        </modal>
    </div>
</template>
<script>
export default {
    props: [
        "products_available",
        "shipping_cost",
        "product_subscribed",
        "extra",
    ],
    //   inject: ['$validator'],

    data() {
        return {
            pa_raw_data: [],
            pa_data: [],
            pa_header: [],
            pa_frames: null,
            po_data: [],
            po_header: [],
            prd_available: [],
            frame: null,
            prod_exist_order: null,
            isModalPreorderVisible: false,
            preorder_qty: null,
            preorder_obj: null,
            loader: null,
            t_amount: 0,
        };
    },
    mounted() {
        if (this.po_header.length > 0) {
            this.po_header.push({
                field: "actions",
                label: "Actions",
                sortable: false,
                tdClass: "text-center",
                width: "10%",
            });
        }
    },
    computed: {
        totalAmount() {
            if (isNaN(this.po_data)) {
                let totalProds = this.po_data.reduce(function (total, current) {
                    return total + current.price * current.qty;
                }, 0);
                this.t_amount = totalProds + (this.totalVolume ?? 0);
                return this.t_amount;
            }
        },
        totalAmountTaxed() {
            if (this.extra.tax_rate) {
                let rate_val = this.extra.tax_rate.value / 100;                
                return this.totalAmount > 0 ? this.t_amount + this.t_amount * rate_val : 0;
            } else {
                return this.t_amount;
            }
        },
        totalVolume() {
            if (
                isNaN(this.po_data) &&
                this.shipping_cost &&
                this.shipping_cost.length > 0
            ) {
                let volume =
                    this.po_data.reduce(function (total, current) {
                        return total + current.volume * current.qty;
                    }, 0) / 10;

                if (this.shipping_cost) {
                    let rate =
                        this.shipping_cost.find((ship) => ship.rate > volume) ??
                        this.shipping_cost.at(-1);

                    return rate.price ?? 0;
                }
            }
            return null;
        },
    },
    watch: {
        products_available(val) {
            if (val) {
                this.pa_frames = Object.assign([], val.frames);
                this.frame = null;
                this.pa_header = Object.assign([], val.headers);
                this.pa_header.push({
                    field: "actions",
                    label: "Actions",
                    sortable: false,
                    tdClass: "text-center",
                    width: "10%",
                });
                this.po_header = Object.assign([], this.pa_header);
                this.prd_available = val.entities;
                let filter_products = this.prd_available;
                if (isNaN(this.prod_exist_order)) {
                    let products_qty = this.prod_exist_order.map(function (p) {
                        return {
                            id: p.product_id,
                            qty_max: p.qty_max,
                        };
                    });
                    let order_product = this.po_data.map(function (p) {
                        return p.product_id;
                    });

                    filter_products = this.prd_available.filter(function (
                        value
                    ) {
                        if (products_qty) {
                            let sel_prd = products_qty.find(
                                (prod) => prod.id == value.product_id
                            );
                            if (sel_prd) {
                                value["qty"] = sel_prd.qty_max;
                            }
                        }

                        return !order_product.includes(value.product_id);
                    });
                } else if (this.prod_exist_order == null) {
                    let order_product = this.po_data.map(function (p) {
                        return p.product_id;
                    });

                    filter_products = this.prd_available.filter(function (
                        value
                    ) {
                        return !order_product.includes(value.product_id);
                    });
                }

                this.pa_data = Object.assign([], filter_products);
                this.pa_raw_data = _.cloneDeep(this.pa_data);

                this.$refs["pa_table"].globalSearchTerm = "";
                this.$refs["pa_table"].reset();
                this.frame = null;
            }
        },
        po_data: {
            handler(val) {
                if (val.length > 0) {
                    if (!this.po_header.some((e) => e.field === "actions")) {
                        this.po_header.push({
                            field: "actions",
                            label: "Actions",
                            sortable: false,
                            tdClass: "text-center",
                            width: "10%",
                        });
                    }

                    let btn_disable = !this.po_data.length;

                    //intersectionWith pres
                    //differenceWith difference
                    if (this.prod_exist_order) {
                        let intersection =
                            _.intersectionWith(
                                this.po_data,
                                this.prod_exist_order,
                                _.isEqual
                            ).length || 0;

                        let difference =
                            _.differenceWith(
                                this.po_data,
                                this.prod_exist_order,
                                _.isEqual
                            ).length || 0;

                        btn_disable =
                            intersection == this.prod_exist_order.length &&
                            !difference;
                    }

                    this.$emit("onDisable", btn_disable);
                } else {
                    this.$emit("onDisable", true);
                }
            },
            deep: true,
        },
    },
    methods: {
        addProduct: function (event, param) {
            let product = _.cloneDeep(
                this.products_available.entities.find(
                    (prod) => prod.id == param.id
                )
            );

            Vue.set(product, "qty_max", parseInt(product.qty) || 60);

            product.qty = !param.qty_sel ? parseInt(1) : param.qty_sel;
            this.po_data.push(product);
            this.$delete(this.pa_data, param.originalIndex);

            let sel_qty = event.target
                .closest("tr")
                .querySelector('[data-label="Qty"]')
                .getElementsByTagName("SELECT")[0];
            process.nextTick(() => {
                sel_qty.selectedIndex = 0;
            });
        },
        removeProduct: function (param) {
            this.$delete(this.po_data, param.originalIndex);

            let prod =
                this.prd_available.find(
                    (prod) => prod.product_id == param.product_id
                ) || null;

            if (prod) {
                let p_id = this.products_available.entities.indexOf(prod);
                this.pa_data.splice(p_id, 0, prod);
            }
        },
        restockProduct: function (e, param) {
            let urlRestock = decodeURIComponent(
                route("product.restock", [param.id])
            );
            this.noticeRestock(param.id, urlRestock)
                .then((result) => {
                    e.target.parentElement.disabled = true;
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        loadProductFromOrder: function (param) {
            this.prod_exist_order = param;
            this.po_data = _.cloneDeep(this.prod_exist_order);
        },
        changeQuantity: function (event, product) {
            Vue.set(product, "qty_sel", parseInt(event.target.value));
        },
        changeQuantityOrder: function (event, product) {
            this.po_data.find((prod) => prod.id == product.id).qty = parseInt(
                event.target.value
            );
        },
        setOptionQuantity(qty, qty_max) {
            let sel_qty = [];
            sel_qty.push('<select class="form-control" ref="sel_qty">');

            let for_qty = qty_max ?? 60;
            for (let index = 1; index <= for_qty; index++) {
                if (index == qty ?? index) {
                    sel_qty.push(
                        '<option selected="selected" value="' + index + '">'
                    );
                } else {
                    sel_qty.push('<option value="' + index + '">');
                }
                sel_qty.push(index);
                sel_qty.push("</option>");
            }
            sel_qty.push("</select>");

            return sel_qty.join("");
        },
        changeFrame: function (param) {
            if (!param.target.value) {
                this.pa_data = this.pa_raw_data;
            } else {
                this.pa_data =
                    this.pa_raw_data.filter(
                        (prod) => prod.frame == param.target.value
                    ) || null;
            }
        },
        reset() {
            this.pa_data = [];
            this.pa_header = [];
            this.po_data = [];
            this.po_header = [];
            this.pa_frames = [];
        },
        registerPreorder(e, param) {
            this.isModalPreorderVisible = true;
            this.preorder_obj = param;
        },
        closeModal() {
            process.nextTick(() => {
                this.isModalPreorderVisible = false;
                this.preorder_qty = null;
                this.$refs.preorderModal.reset();
                this.loader.hide();
            });
        },
        async clickModal() {
            const isValid = await this.$refs.preorderModal
                .validate()
                .then((result) => {
                    if (result) {
                        let urlPreorder = decodeURIComponent(
                            route("product.preorder.create", [
                                this.preorder_obj.id,
                            ])
                        );

                        const formData = new FormData();
                        formData.append("qty", this.preorder_qty);

                        this.loader = this.showLoader(
                            this.$refs.modalPreorder.$refs.modalContent
                        );

                        this.noticeRestock(formData, urlPreorder)
                            .then((result) => {
                                this.closeModal();
                                this.product_subscribed.push(
                                    this.preorder_obj.id
                                );
                            })
                            .catch((error) => {
                                console.log(error);
                                this.catchResponse(error);
                            });
                    }
                });
            // if (this.order.status.key == "Confirmed") {
            //     this.order["message"] = this.message;
            //     await this.$parent.$parent.$refs.btn_save_order.click();
            // } else if (this.order.status.key == "Shipped") {
            //     this.order["message"] = this.message;
            //     await this.shipOrder();
            // }
        },

        //TODO: TRANSFORM IN UTILITIES FUNCTION
        // objectToFormData(obj) {
        //     const formData = new FormData();

        //     Object.entries(obj).forEach(([key, value]) => {
        //         formData.append(key, value);
        //     });

        //     return formData;
        // },
    },
    directives: {
        featherlightGallery: {
            inserted(el) {
                $(el).featherlightGallery({
                    filter: "a",
                    previousIcon: "&#8249;" /* Code that is used as previous icon */,
                    nextIcon: "&#8250;" /* Code that is used as next icon */,
                    galleryFadeIn: 100 /* fadeIn speed when slide is loaded */,
                    galleryFadeOut: 300 /* fadeOut speed before slide is loaded */,
                });
            },
        },
    },
};
</script>
