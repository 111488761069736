var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 9,
        },"search-options":{
            enabled: true,
        },"styleClass":"vgt-table bordered","compactMode":""},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'product')?_c('span',[_c('span',{style:([
                        props.row.preorder
                            ? { color: 'green' }
                            : { color: 'red' },
                    ])},[_vm._v(_vm._s(props.row.product))])]):(props.column.field == 'qty')?_c('span',[(props.row.preorder)?_c('span',{staticStyle:{"font-weight":"bold","color":"green"}},[_vm._v("PRE-ORDER")]):(props.row.qty == 0)?_c('span',{staticStyle:{"font-weight":"bold","color":"red"}},[_vm._v("OUT OF STOCK")]):(props.row.qty == -1 || props.row.qty == null)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(props.row.qty))])]):(props.column.field == 'image')?_c('span',{directives:[{name:"featherlight-gallery",rawName:"v-featherlight-gallery"}],staticClass:"image-gallery",attrs:{"data-featherlight-gallery":""}},[_c('a',{attrs:{"href":_vm.parsePath(props.row.image),"role":"button"}},[(props.row.image)?_c('img',{directives:[{name:"image-fall-back",rawName:"v-image-fall-back"}],staticClass:"w-50 mx-auto d-block",attrs:{"src":_vm.parsePath(props.row.image, 'thumb'),"data-fail":_vm.parsePath(props.row.image)}}):_vm._e()]),_vm._v(" "),_vm._l((props.row.gallery),function(thumb,index){return _c('a',{key:index,staticClass:"featured",attrs:{"href":thumb}},[_c('img',{staticClass:"d-none",attrs:{"src":thumb}})])})],2):_c('span',[_vm._v("\n                "+_vm._s(props.formattedRow[props.column.field])+"\n            ")]),_vm._v(" "),(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn btn-primary btn-circle btn-sm",on:{"click":function($event){return _vm.editData(props.row)}}},[_c('i',{staticClass:"fas fa-pencil-alt"})]),_vm._v(" "),_c('button',{staticClass:"btn btn-circle btn-sm",class:props.row.status &&
                        props.row.status.key != 'Pending' &&
                        props.row.status.key != 'Failed'
                            ? 'btn-secondary'
                            : 'btn-danger',attrs:{"disabled":props.row.status &&
                        props.row.status.key != 'Pending' &&
                        props.row.status.key != 'Failed'},on:{"click":function($event){return _vm.deleteData(props.row)}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }