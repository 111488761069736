<template>
  <div>
    <div class="row mt-5">
      <div class="col-md-5">
        <h4>Lens</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th class="text-center" scope="col">Qty.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in instr.lens" :key="index">
                <td>{{ index }}</td>
                <td class="text-center">{{ p }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5 offset-md-2">
        <h4>Frame</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th class="text-center" scope="col">Qty.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in instr.frame" :key="index">
                <td>{{ index }}</td>
                <td class="text-center">{{ p }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-12">
        <h4>Detail Products</h4>
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Product</th>
                <th scope="col">Lens</th>
                <th scope="col">Frame</th>
                <th scope="col">Qty.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, index) in instr.products" :key="index">
                <td>{{ p.product }}</td>
                <td>{{ p.lens_color }}</td>
                <td>{{ p.frame_color }}</td>
                <td class="text-center">{{ p.qty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['extra'],
  data() {
    return {
      instr: this.extra.instruction
    }
  }
}
</script>
