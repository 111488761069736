<template>
  <div class="col-md-4 offset-md-4">
    <div class="login-panel panel panel-default">
      <div class="panel-body">
        <ValidationObserver
          ref="formObs"
          v-slot="{ handleSubmit, reset }"
        >
          <form
            id="password-form"
            @submit.prevent="handleSubmit(processForm)"
            @reset.prevent="reset(resetForm())"
            class="main-form"
            role="form"
          >
            <fieldset>
              <div class="form-group">
                <ValidationProvider
                  name="Old Password"
                  rules="required"
                  v-slot="{ classes, errors }"
                  vid="old_password"
                >
                  <input
                    type="password"
                    v-model="passwords.old_password"
                    class="form-control"
                    :class="{ 'border-danger': classes.failed }"
                    placeholder="Old Password"
                    autocomplete="current-password"
                  />
                  <i
                    v-show="errors[0]"
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                  <span class="help text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="New Password"
                  rules="required|is_not:@old_password|min:6|max:12"
                  v-slot="{ classes, errors }"
                  vid="new_password"
                >
                  <input
                    type="password"
                    v-model="passwords.new_password"
                    class="form-control"
                    :class="{ 'border-danger': classes.failed }"
                    placeholder="New Password"
                    autocomplete="new-password"
                  />
                  <i
                    v-show="errors[0]"
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                  <span class="help text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <ValidationProvider
                  name="Repeat New Password"
                  rules="required|confirmed:new_password"
                  v-slot="{ classes, errors }"
                >
                  <input
                    v-model="passwords.confirm_password"
                    type="password"
                    class="form-control"
                    :class="{ 'border-danger': classes.failed }"
                    placeholder="Repeat New Password"
                    autocomplete="new-password"
                  />
                  <i
                    v-show="errors[0]"
                    class="fas fa-exclamation-circle text-danger"
                  ></i>
                  <span class="help text-danger">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <!-- Change this to a button or input when using this as a form -->
              <div class="form-group">
                <button class="btn btn-primary">Save</button>
                <button type="reset" class="btn btn-danger">
                  Cancel
                </button>
              </div>
            </fieldset>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>
<script>
function initialState() {
  return {
    passwords: {
      old_password: '',
      new_password: '',
      confirm_password: ''
    }
  }
}
export default {
  data() {
    return initialState()
  },
  methods: {
    resetForm() {
      Object.assign(this.$data, initialState())
    },
    processForm() {
      let loader = this.showLoader(this.$el)
      this.updateCall(this.passwords, route('password.change'))
        .then((result) => {
          loader.hide()
          this.$refs.formObs.reset()
          this.resetForm()          
        })
        .catch((error) => {
          console.log(error)
          loader.hide()
          this.$refs.formObs.setErrors(error.data.error.errors)
        })
    }
  }
}
</script>
