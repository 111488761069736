<template>
  <div class="grid mb-2">
    <div class="row">
      <div class="col-md-5 offset-1">
        <h5>Retailer Data:</h5>
        Name: {{ user.name }}
        <br />
        Email: {{ user.email }}
        <br />
        Phone: {{ user.profile.phone }}
        <br />
        VAT: {{ user.profile.VAT }}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-5 offset-1">
        <h5>Billing Address:</h5>
        Address: {{ user.profile.bill_address }}
        <br />
        Town: {{ user.profile.bill_town }}
        <br />
        ZIP: {{ user.profile.bill_zip }}
        <br />
        Province: {{ user.profile.bill_province }}
        <br />
        Country: {{ user.profile.bill_country }}
      </div>
      <div class="col-md-5 md-offset-1">
        <h5>Shipping Address:</h5>
        Address: {{ user.profile.ship_address }}
        <br />
        Town: {{ user.profile.ship_town }}
        <br />
        ZIP: {{ user.profile.ship_zip }}
        <br />
        Province: {{ user.profile.ship_province }}
        <br />
        Country: {{ user.profile.ship_country }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['entity'],
  data() {
    return {
      user: this.entity.user
    }
  }
}
</script>
