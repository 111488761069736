const Utilities = {
    install(Vue, options) {
        Vue.mixin({
            methods: {
                asset(path) {
                    var base_path = window._asset || '';
                    return base_path + path;
                },
                parsePath(value, size = null) {

                    let thumbSize = null;

                    switch (size) {
                        case 'thumb':
                                thumbSize = '-300x200';
                            break;
                            case 'micro':
                                thumbSize = '-100x100';
                            break;
                            case 'fall':
                                thumbSize = '-150x150';
                            break;
                    
                        default:
                            break;
                    }

                    if (value) {

                        const regex = /-\d{2,5}x\d{2,5}\./;

                        if (size) {
                            if (regex.test(value)) {
                                // If the pattern is found, replace it
                                return value.replace(regex, thumbSize + '.');
                            } else {
                                // If the pattern is not found, insert it before the file extension
                                return value.replace(/(\.[a-zA-Z]{3,4})$/, `${thumbSize}$1`);
                            }
                        }
                        else {

                            let filename = value
                                .split('/')
                                .pop()
                                .split('.')
                                .slice(0, -1)
                                .join('.')

                            let ext = value.split('.').pop()

                            return value.replace(regex, '.');

                            return value.replace(
                                value.split('/').pop(),
                                filename.concat('.').concat(ext)
                            )
                        }
                    }
                    return value
                },
                showLoader(container) {
                    return this.$loading.show({
                        container: container
                    })
                }
            }
        })
    }
}

export default Utilities
