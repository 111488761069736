<template>
  <span>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label>Phone</label>
        <ValidationProvider
          name="phone"
          :rules="`${!is_admin ? 'required' : ''}`"
          v-slot="{ classes, errors }"
        >
          <input
            v-model="profile.phone"
            type="text"
            class="form-control"
            :class="{
              'border-danger': classes.failed
            }"
            v-uppercase
          />
          <i
            v-show="errors[0]"
            class="fas fa-exclamation-circle text-danger"
          ></i>
          <span class="help text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form-group col-md-6">
        <label>VAT</label>
        <ValidationProvider
          name="VAT"
          :rules="''"
          v-slot="{ classes, errors }"
        >
          <input
            v-model="profile.VAT"
            type="numeric"
            class="form-control"
            :class="{
              'border-danger': classes.failed
            }"
          />
          <i
            v-show="errors[0]"
            class="fas fa-exclamation-circle text-danger"
          ></i>
          <span class="help text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-row" v-if="is_admin">
      <div class="form-group col-md-6">
        <label>Tax Rate</label>
        <ValidationProvider
          name="tax rate"
          :rules="`${is_admin ? 'required' : ''}`"
          v-slot="{ classes, errors }"
        >
        <!-- v-model="pricelist_id" -->
           <select
              v-model="profile.tax_rate"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
            >
              <option selected disabled>
                Please select one
              </option>
              <option
                v-for="(value, name) in tax_rates"
                :value="value"  
                v-bind:key="value"              
              >
                {{ name }}
              </option>
            </select>
          <i
            v-show="errors[0]"
            class="fas fa-exclamation-circle text-danger"
          ></i>
          <span class="help text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="form-group col-md-6">
        <label>Notation</label>
        <ValidationProvider
          name="Notation"
          :rules="''"
          v-slot="{ classes, errors }"
        >
          <select
              v-model="notation"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
            >
             <option selected >
              </option>
              <option
                v-for="value in notations"
                v-bind:key="value"
              >
                {{ value }}
              </option>
            </select>
          <i
            v-show="errors[0]"
            class="fas fa-exclamation-circle text-danger"
          ></i>
          <span class="help text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <h5>
          Billing<span v-if="profile.same_address">/Shipping</span>
          Address
        </h5>
        <div class="form-group">
          <label for="same_address">Same Address</label>
          <input
            id="same_address"
            type="checkbox"
            @change="changeAddress"
            v-model="profile.same_address"
          />
        </div>
        <div class="form-group">
          <label>Address</label>
          <ValidationProvider
            name="Address Bill"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.bill_address"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger h6">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Town</label>
          <ValidationProvider
            name="Town Bill"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.bill_town"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Province</label>
          <ValidationProvider
            name="Province Bill"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.bill_province"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>ZIP</label>
          <ValidationProvider
            name="Zip Bill"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.bill_zip"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Country</label>
          <ValidationProvider
            name="Country Bill"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <select
              v-model="profile.bill_country"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
            >
             <option selected >
              </option>
              <option
                v-for="(value, name) in countries"
                v-bind:key="value"
                :value="name"
              >
                {{ value }}
              </option>
            </select>
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="col-md-6" v-if="!profile.same_address">
        <h5>Shipping Address</h5>
        <div class="form-group mt-6">
          <label>Address</label>
          <ValidationProvider
            name="Address Ship"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.ship_address"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Town</label>
          <ValidationProvider
            name="Town Ship"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.ship_town"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Province</label>
          <ValidationProvider
            name="Province Ship"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.ship_province"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>ZIP</label>
          <ValidationProvider
            name="Zip Ship"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <input
              v-model="profile.ship_zip"
              type="text"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
              v-lowercase
            />
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Country</label>
          <ValidationProvider
            name="Country Ship"
            :rules="`${!is_admin ? 'required' : ''}`"
            v-slot="{ classes, errors }"
          >
            <select
              v-model="profile.ship_country"
              class="form-control"
              :class="{
                'border-danger': classes.failed
              }"
            >
             <option selected >
              </option>
              <option
                v-for="(value, name) in countries"
                v-bind:key="value"
                :value="name"
              >
                {{ value }}
              </option>
            </select>
            <i
              v-show="errors[0]"
              class="fas fa-exclamation-circle text-danger"
            ></i>
            <span class="help text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ['entity', 'is_admin', 'extra'],
  data() {
    return {
      profile: {
        same_address: false
      },
      tax_rates: {},
      notations: {},   
      countries: {}, 
      notation: '',  
    }
  },
  mounted() {    
    if (_.isObject(this.entity) && !isNaN(this.entity.id)) {
      this.profile = Object.assign({}, this.entity.profile)      
    }

    if(this.is_admin){
      this.tax_rates = this.extra.tax_rates
      this.notations = this.extra.notations        
    }
    this.countries = this.extra.countries
  },
  watch: {
    entity(newVal) {
      if (_.isObject(newVal) && !isNaN(newVal.id)) {
        this.profile = Object.assign({}, newVal.profile)
         if (this.is_admin) {
          this.notation = newVal.profile.notation ?? ''
        }        
      }
      else{
        this.profile = {}
      }
    },
    notation(newVal){
      this.profile.notation = newVal ?? ''
    }
    
  },
  methods: {
    changeAddress() {
      this.profile.ship_address =
        this.entity.id && !this.entity.profile.same_address
          ? this.entity.profile.ship_address
          : null

      this.profile.ship_town =
        this.entity.id && !this.entity.profile.same_address
          ? this.entity.profile.ship_town
          : null

      this.profile.ship_province =
        this.entity.id && !this.entity.profile.same_address
          ? this.entity.profile.ship_province
          : null

      this.profile.ship_zip =
        this.entity.id && !this.entity.profile.same_address
          ? this.entity.profile.ship_zip
          : null

      this.profile.ship_country =
        this.entity.id && !this.entity.profile.same_address
          ? this.entity.profile.ship_country
          : null
    }
  }
}
</script>
