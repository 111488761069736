import { extend, localize } from 'vee-validate'
import en from 'vee-validate/dist/locale/en.json'
// import itMessages from './it-rules.json';
import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule])
})

extend('url', {
    validate: value => {
        if (value) {
            return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                value
            )
        }
        return false
    }
})

localize('en', en)
// localize({
//     it: itMessages
// })

export { ValidationObserver, ValidationProvider } from 'vee-validate'
