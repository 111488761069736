<template>
    <div class="card shadow mb-4 col-md-8">
        <!-- Card Header - Accordion -->
        <a
            :class="{ disabled: addDisabled }"
            @click.prevent="enableAdd()"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-agent"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                <span v-if="!fresh_agent.id">Add New</span
                ><span v-else>Modify</span> Agent
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div :class="this.accordion.collapse" class="collapse" id="new-agent">
            <div class="card-body">
                <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
                    <form
                        id="agent-form"
                        @submit.prevent="handleSubmit(processForm)"
                        class="main-form"
                        role="form"
                    >
                        <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Name</label>
                                <ValidationProvider
                                    name="name"
                                    rules="required"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_agent.name"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        v-lowercase
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>

                            <div class="form-group offset-md-2 col-md-5">
                                <label>Email</label>
                                <ValidationProvider
                                    name="email"
                                    rules="required|email"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_agent.email"
                                        :disabled="fresh_agent.id"
                                        type="email"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        v-lowercase
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <!--  <div class="form-row">
                            <div class="form-group col-md-5">
                                <label>Phone</label>
                                <ValidationProvider
                                    name="phone"
                                    v-slot="{ classes, errors }"
                                >
                                    <input
                                        v-model="fresh_agent.phone"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'border-danger': classes.failed,
                                        }"
                                        v-uppercase
                                    />
                                    <i
                                        v-show="errors[0]"
                                        class="fas fa-exclamation-circle text-danger"
                                    ></i>
                                    <span class="help text-danger">{{
                                        errors[0]
                                    }}</span>
                                </ValidationProvider>
                            </div>
                        </div> -->
                        <div class="form-row pb-4">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label>Choice Retailers</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <multi-picker
                                    ref="retailers_picker"
                                    :options="options"
                                    :selected="fresh_agent.retailers"
                                ></multi-picker>
                            </div>
                        </div>
                        <div class="form-group">
                            <input hidden v-model="fresh_agent.id" />
                            <!-- <button :disabled="invalid" class="btn btn-primary">Save</button> -->
                            <button class="btn btn-primary">Save</button>
                            <div @click="resetForm()" class="btn btn-danger">
                                Cancel
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import MultiPicker from "../common/Multipicker";

export default {
    components: { MultiPicker },
    props: ["entity", "extra"],
    data() {
        return {
            accordion: {
                aria: false,
                collapse: "",
            },
            addDisabled: false,
            options: this.extra.retailers,
            is_admin: this.extra.is_admin,
            fresh_agent: {
                retailers: [],
            },
        };
    },
    watch: {
        entity(newVal) {
            if (this.entity.id) {
                this.getData(route("agent.show", [newVal.id])).then(
                    (result) => {
                        this.fresh_agent = _.cloneDeep(this.entity);
                        this.fresh_agent.retailers = result.data.retailers;
                    }
                );

                this.addDisabled = true;
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            }
        },
    },
    methods: {
        resetForm() {
            this.accordion.aria = false;
            this.accordion.collapse = "";
            this.addDisabled = false;
            this.fresh_agent = {};
            this.$refs.formObs.reset();
            this.$emit("resetForm");
        },
        enableAdd() {
            if (!this.accordion.aria && this.accordion.collapse == "") {
                this.accordion.aria = true;
                this.accordion.collapse = "show";
            } else {
                this.resetForm();
            }
        },
        processForm() {
            this.fresh_agent.retailers =
                this.$refs.retailers_picker.selectedResources.map(
                    ({ id }) => id
                );
                
            this.$refs.formObs.validate().then((result) => {
                if (this.entity.id) {
                    this.$emit("updateItem", this.fresh_agent);
                } else {
                    this.$emit("createItem", this.fresh_agent);
                }
            });
        },
    },
};
</script>
