<template>
    <div class="card shadow mb-4 col-md-6">
        <!-- Card Header - Accordion -->
        <a
            :class="{ disabled: addDisabled }"
            @click.prevent="enableAdd()"
            ref="accordionPanel"
            class="d-block card-header py-3"
            data-toggle="collapse"
            role="button"
            :aria-expanded="this.accordion.aria"
            aria-controls="new-fc"
        >
            <h6 class="m-0 font-weight-bold text-primary">
                <span v-if="!entity.id">Add New</span>
                <span v-else>Modify</span> Price List
            </h6>
        </a>
        <!-- Card Content - Collapse -->
        <div
            :class="this.accordion.collapse"
            class="collapse"
            id="new-framecolor"
        >
            <div class="card-body">
                <ValidationObserver ref="formObs" v-slot="{ handleSubmit }">
                    <!--  -->
                    <form
                        id="pricelist-form"
                        class="main-form"
                        role="form"
                        @submit.prevent="handleSubmit(processForm)"
                    >
                        <div class="form-group">
                            <label>Name</label>
                            <ValidationProvider
                                name="name"
                                rules="required"
                                v-slot="{ classes, errors }"
                            >
                                <select
                                    v-if="!addDisabled"
                                    class="form-control"
                                    v-model.lazy="entity"
                                    :class="{
                                        'border-danger': classes.failed,
                                    }"
                                >
                                    <option selected>Please select one</option>
                                    <option
                                        v-for="p in pricelists"
                                        v-bind:key="p.id"
                                        v-bind:value="p"
                                    >
                                        {{ p.name }}
                                    </option>
                                </select>
                                <input
                                    v-else
                                    v-model="new_ent.name"
                                    type="text"
                                    class="form-control"
                                    :class="{ 'border-danger': classes.failed }"
                                    v-uppercase
                                />
                                <i
                                    v-show="errors[0]"
                                    class="fas fa-exclamation-circle text-danger"
                                ></i>
                                <span class="help text-danger">{{
                                    errors[0]
                                }}</span>
                            </ValidationProvider>
                        </div>
                        <form-shipping-cost
                            v-if="addDisabled || entity.id"
                            ref="form_shipping_cost"
                            :shipping_class="shipping_rates"
                            :warn_shipping_cost="warn_shipping_cost"
                            :add_disabled="addDisabled"
                        ></form-shipping-cost>
                        
                        <div class="form-row hide_price-group"
                        v-if="addDisabled || entity.id">
                            
                            <div class="form-group col-md-5">
                                <label>Hide Suggested Price</label>
                            </div>                            
                            <div class="form-group offset-md-1 col-md-1">                             
                                <input
                                    v-model="new_ent.hide_price"
                                    type="checkbox"
                                    class="form-control"
                                    :disabled="!addDisabled"                                 
                                />
                            </div>
                        </div>
                        <div class="form-group" v-if="addDisabled">
                            <button class="btn btn-primary">Save</button>
                            <div
                                @click="addDisabled = false"
                                class="btn btn-danger"
                            >
                                Cancel
                            </div>
                            <div
                                v-if="addDisabled && entity.id"
                                @click="deleteData(entity)"
                                class="btn btn-danger float-right"
                            >
                                <!-- && entity.id" -->
                                <i class="fas fa-trash-alt"></i>
                                DELETE
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["entities", "defaultRates"],
    data() {
        return {
            pricelists: this.entities,
            entity: {},
            new_ent: {
                hide_price: false
            },
            accordion: {
                aria: true,
                collapse: "show",
            },
            addDisabled: false,
            shipping_rates: {},
            warn_shipping_cost: false,
        };
    },
    watch: {
        entity(newVal) {
            if (_.isObject(newVal) && !isNaN(newVal.id)) {
                this.warn_shipping_cost = !newVal.shipping_cost.length;
                this.new_ent.hide_price = newVal.hide_price
                this.$emit("fillTable", newVal);
            } else {
                this.warn_shipping_cost = false;
                this.shipping_rates = _.cloneDeep(this.defaultRates);
                this.new_ent = {};
                this.$emit("fillTable", null);
            }
        },
    },
    methods: {
        resetForm() {
            this.addDisabled = false;
            this.$refs.formObs.reset();
            this.entity = {};
            this.new_ent = {};
            this.shipping_rates = null;
            this.$refs.shipping_cost.shipping_rates = {};
            this.$emit("resetForm");
        },
        enableAdd() {
            if (
                !_.isString(this.entity) &&
                !_.isNaN(this.entity) &&
                !_.isEmpty(this.entity)
            ) {
                this.new_ent = Object.assign({}, this.entity);
            } else {
                this.new_ent = {};
                this.shipping_rates = _.cloneDeep(this.defaultRates);
            }
            this.addDisabled = true;
        },
        processForm() {
            this.new_ent.shipping_cost =
                this.$refs.form_shipping_cost.shipping_class;
            this.$refs.formObs.validate().then((result) => {
                this.$emit("saveItem", this.new_ent);
            });
        },
        deleteData: function (params) {
            if (confirm("Do you really want to delete?")) {
                this.$emit("onDelete", params);
                this.resetForm();
            }
        },
    },
};
</script>
