<template>
    <div>
        <div class="row">
            <form-genuine_code
                ref="form_genuinecode"
                :entity="entity"
                :extra="extra"
                @importItems="importItems"
                @resetForm="resetForm"
                @onShowTable="tableDisplay"
            ></form-genuine_code>
        </div>
        <div class="list_table">
            <div class="row">
                <div class="col-lg-12">
                    <div class="panel panel-default">
                        <!-- /.panel-heading -->
                        <div class="panel-body">
                            <genuine-code-table
                                ref="table_genuinecode"
                                :entities="entities"
                                :headers="headers"
                                @onSearchCode="searchCode"
                                @onReset="latestCode"
                            ></genuine-code-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "headers",
        "lvEntities",
        "urls",
        "entityName",
        "dataExtra",
        "onlyView",
    ],
    data() {
        return {
            entities: this.lvEntities,
            entity: {},
            extra: this.dataExtra ?? null,
            show_table: true,
            no_action: this.onlyView ?? false,
            loader: false,
        };
    },
    computed: {},
    methods: {
        importItems(entity) {
            this.loaderOn();
            this.createCall(entity, route(this.entityName.concat(".store")))
                .then((result) => {
                    this.$refs.table_genuinecode.getRecords(result.data.data.genuine_codes);
                    this.$refs.form_genuinecode.forceDownload(
                        result.data.data.generated_codes
                    );
                    this.loader ? this.loader.hide() : null;
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        resetForm() {
            this.entity = {};
            this.table_display = true;
        },
        tableDisplay(show) {
            this.show_table = show;
        },
        manageResponse(result) {
            this.loader ? this.loader.hide() : null;
            this.entities = result.data.data;
        },
        catchResponse(error) {
            this.loader ? this.loader.hide() : null;
            if (error.status == 422) {
                console.log(error.data.error.errors);
                this.$refs.form_genuinecode.$refs.formObs.setErrors(
                    error.data.error.errors
                );
            } else if (error.status == 400) {
                this.$refs.form_genuinecode.loader.hide();
            }
        },
        async searchCode(code) {
            const searchUrl = route(this.entityName.concat(".search"));
            this.searchData(code, searchUrl)
                .then((result) => {
                    this.$refs.table_genuinecode.getRecords(result.data.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        async latestCode() {
            const indexUrl = route(this.entityName);
            this.defaultData(indexUrl)
                .then((result) => {                    
                    this.$refs.table_genuinecode.getRecords(result.data.data);
                })
                .catch((error) => {
                    console.log(error);
                    this.catchResponse(error);
                });
        },
        loaderOn() {
            this.loader = this.showLoader(this.$el);
        },
    },
};
</script>
