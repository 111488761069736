<template>
    <div>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 9,
            }"
            :search-options="{
                enabled: true,
            }"
            styleClass="vgt-table bordered"
            compactMode
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'product'">
                    <span
                        :style="[
                            props.row.preorder
                                ? { color: 'green' }
                                : { color: 'red' },
                        ]"
                        >{{ props.row.product }}</span
                    >
                </span>
                <span v-else-if="props.column.field == 'qty'">
                    <span
                        v-if="props.row.preorder"
                        style="font-weight: bold; color: green"
                        >PRE-ORDER</span
                    >
                    <span
                        v-else-if="props.row.qty == 0"
                        style="font-weight: bold; color: red"
                        >OUT OF STOCK</span
                    >
                    <span
                        v-else-if="props.row.qty == -1 || props.row.qty == null"
                        >-</span
                    >
                    <span v-else>{{ props.row.qty }}</span>
                </span>
                <span
                    v-else-if="props.column.field == 'image'"
                    class="image-gallery"
                    data-featherlight-gallery
                    v-featherlight-gallery
                >
                    <a :href="parsePath(props.row.image)" role="button">
                        <img
                            v-if="props.row.image"
                            class="w-50 mx-auto d-block"
                            :src="parsePath(props.row.image, 'thumb')"
                            :data-fail="parsePath(props.row.image)"
                            v-image-fall-back 
                        />
                    </a>
                    <a
                        v-for="(thumb, index) in props.row.gallery"
                        :href="thumb"
                        :key="index"
                        class="featured"
                    >
                        <img class="d-none" :src="thumb" />
                    </a>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
                <span v-if="props.column.field == 'actions'">
                    <button
                        class="btn btn-primary btn-circle btn-sm"
                        @click="editData(props.row)"
                    >
                        <i class="fas fa-pencil-alt"></i>
                    </button>
                    <button
                        class="btn btn-circle btn-sm"
                        :class="
                            props.row.status &&
                            props.row.status.key != 'Pending' &&
                            props.row.status.key != 'Failed'
                                ? 'btn-secondary'
                                : 'btn-danger'
                        "
                        @click="deleteData(props.row)"
                        :disabled="
                            props.row.status &&
                            props.row.status.key != 'Pending' &&
                            props.row.status.key != 'Failed'
                        "
                    >
                        <i class="fas fa-times"></i>
                    </button>
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
export default {
    name: "common-table",
    props: ["headers", "entities", "no_action"],
    data() {
        if (this.no_action == false) {
            this.headers.push({
                field: "actions",
                label: "Actions",
                sortable: false,
                tdClass: "text-center",
                width: "10%",
            });
        }
        return {
            columns: this.headers,
        };
    },
    computed: {
        rows: function () {
            return this.entities;
        },
    },
    methods: {
        editData: function (params) {
            this.$emit("onEdit", params);
        },
        deleteData: function (params) {
            if (confirm("Do you really want to delete?")) {
                this.$emit("onDelete", params);
            }
        },
    },
    directives: {
        featherlightGallery: {
            inserted(el) {
                $(el).featherlightGallery({
                    filter: "a",
                    previousIcon: "&#8249;" /* Code that is used as previous icon */,
                    nextIcon: "&#8250;" /* Code that is used as next icon */,
                    galleryFadeIn: 100 /* fadeIn speed when slide is loaded */,
                    galleryFadeOut: 300 /* fadeOut speed before slide is loaded */,
                });
            },
        },
    },
};
</script>
